import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import WithTooltip from 'common/components/WithTooltip/WithTooltip';
import {
  grey,
  lighterNavy,
  lightGreyBackground,
  lightGreyText,
} from 'common/styles/colors';
import { PackPlanCombo } from 'features/pack-plan-views/components/PackPlanTable/PackPlanRow';
import { ReqToSchedule } from 'features/pack-plan-views/pages/PackPlanView/PackPlanView';
import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Constants } from 'utils/constants';
import { getISODateOnlyString, getWeekDay } from 'utils/dateTimeHelpers';
import {
  DateColumnContainer,
  PackDate,
  PackDay,
  PHCode,
} from '../../pack-plan-views/components/PackPlanTable/styles';
import { Title } from '../../pack-plan-views/pages/PackPlanView/styles';
import { AvailableBlocksTable } from '../components/AvailableBlocksTable';
import { AvailBlockToPick } from '../types';
import { SchedulePickWizardValues } from './SchedulePickWizardView';

const ComboToSchedule = styled.section`
  height: 48px;
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const PHContainer = styled(PHCode)`
  margin-right: 10px;
`;

const DateContainer = styled(DateColumnContainer)`
  max-width: 58px;
  width: 100%;
`;

const ComboContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 145px;
  margin-left: 5px;
`;

const BinsContainer = styled(PHCode)`
  min-width: 30px;
  max-width: 54px;
  margin-left: 10px;
`;

const ScheduleBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 20px;
`;

const ScheduleBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 160px;
  height: 40px;
  background: ${lighterNavy};
  color: ${lightGreyText};
  font-family: KanitRegular;
  font-size: 18px;
  letter-spacing: 0.12em;
  border: none;
  border-radius: 4px;

  &:disabled {
    background: ${lightGreyBackground};
    color: ${grey};
  }
`;

const Chevron = styled(ChevronRightIcon)`
  margin-left: 10px;
`;

export const ScheduleNewPickView: FC = () => {
  const history = useHistory();
  const { state } = useLocation<ReqToSchedule | Record<string, never>>();
  const [packHouse] = useState(state?.packHouse);
  const [date] = useState(state?.date ? new Date(state?.date) : null);
  const [variety] = useState(state?.variety);
  const [market] = useState(state?.market);
  const [size] = useState(state?.size);
  const [bins] = useState(state?.bins);
  const [requestId] = useState(state?.binId);
  const [selectionExists] = useState(
    packHouse && date && variety && market && size && bins && requestId,
  );
  const [blocksToSched, setBlockstoSched] = useState([] as AvailBlockToPick[]);

  useEffect(() => {
    if (!selectionExists) history.replace(`${Constants.routes.PACK_PLAN}`);
  }, [history, selectionExists]);

  return (
    <>
      <Title>Schedule New Pick</Title>
      <ComboToSchedule>
        {selectionExists && (
          <>
            <PHContainer>
              <WithTooltip title='Pack House' tooltipText={packHouse.name}>
                <span>{packHouse.code}</span>
              </WithTooltip>
            </PHContainer>
            <DateContainer>
              <PackDay>{date ? getWeekDay(date) : ''}</PackDay>
              <PackDate>
                {date ? `${date.getMonth() + 1}/${date.getDate()}` : ''}
              </PackDate>
            </DateContainer>
            <ComboContainer>
              <PackPlanCombo combo={{ variety, market, size }} />
            </ComboContainer>
            <BinsContainer>{bins}</BinsContainer>
          </>
        )}
      </ComboToSchedule>
      {variety && (
        <>
          {date && packHouse && (
            <AvailableBlocksTable
              varietyId={variety.id}
              pickDay={getISODateOnlyString(date)}
              packHouseId={packHouse.id}
              setBlocks={setBlockstoSched}
            />
          )}
          <ScheduleBtnContainer>
            <ScheduleBtn
              type='button'
              onClick={() => {
                const schedulingPayload: SchedulePickWizardValues = {
                  planned: {
                    blocks: blocksToSched,
                    packHouse,
                    variety,
                    size,
                    requestId,
                  },
                };
                history.push({
                  pathname: `${Constants.routes.PICK_SCHEDULE}${Constants.routes.NEW_PICK}`,
                  state: schedulingPayload,
                });
              }}
              disabled={!blocksToSched.length}
            >
              SCHEDULE <Chevron />
            </ScheduleBtn>
          </ScheduleBtnContainer>
        </>
      )}
    </>
  );
};
