import { CreateOrViewWrapper } from 'common/components/DetailControls';
import { SizeEstimate } from 'common/models/growerBlock/sizeEstimate';
import { grey, lighterNavy } from 'common/styles/colors';
import { FormPadding } from 'common/styles/form';
import { FC } from 'react';
import { SizesFields } from './fields/SizesFields';
import styled from 'styled-components';
import { useRbac } from 'features/rbac';

const StyleWrapper = styled.div`
  padding: ${FormPadding} 0;

  div.row > div {
    width: 50%;
  }

  .row.headings {
    margin: 0;
    padding-bottom: 5px;
    color: ${lighterNavy};
    border-bottom: 1px solid ${grey};

    div {
      font-weight: bold;
      padding: 0;
      text-align: center;
    }
  }
`;

export const SizesForm: FC<{ sizeEstimates: SizeEstimate[] }> = ({
  sizeEstimates,
}) => {
  const { userHasPermission } = useRbac();
  const isNewSizeEst = !!sizeEstimates.find(
    sizeEst => sizeEst.percentValue > 0,
  );

  return (
    <CreateOrViewWrapper
      formObject={isNewSizeEst}
      message='No size estimates yet.'
      canCreate={userHasPermission('evalEstTabs:create/edit')}
    >
      <StyleWrapper>
        <div className='row headings'>
          <div>Size</div>
          <div>Percentage</div>
        </div>
        <SizesFields estimates={sizeEstimates} />
      </StyleWrapper>
    </CreateOrViewWrapper>
  );
};
