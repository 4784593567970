import { DateWithTime, DateWithoutTime } from './dateType';

export enum GibbRate {
  None,
  Half,
  Full,
  Late,
}

export interface Evaluation {
  id: number;
  deletedAt: DateWithTime;
  updatedAt: DateWithTime;
  evaluationDate: DateWithoutTime;
  gibb: boolean;
  gibbRate: GibbRate;
  market: string;
  color: number;
  drop: number;
  priority: number;
  condition: number;
}
