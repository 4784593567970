import { environment } from '../environment/index';

interface IPattern {
  DIGIT_REGEX: RegExp;
  EMAIL_REGEX: RegExp;
  SYMBOL_REGEX: RegExp;
  US_PHONE_REGEX: RegExp;
  LOWERCASE_REGEX: RegExp;
  UPPERCASE_REGEX: RegExp;
}
interface IErrorMessages {
  COMBINATION_EXISTS: string;
  FIELD_REQUIRED: string;
  PASSWORD_LENGTH: string;
  PASSWORD_LOWERCASE: string;
  PASSWORD_UPPERCASE: string;
  PASSWORD_SPECIAL_CHARACTER: string;
  PASSWORD_NUMBER: string;
  PASSWORD_MUST_MISMATCH: string;
  PASSWORD_MUST_MATCH: string;
  EMAIL_REQUIRED: string;
  INVALID_EMAIL: string;
  PASSWORD_REQUIRED: string;
  EMAIL_MATCH: string;
  FIRST_NAME_REQUIRED: string;
  LAST_NAME_REQUIRED: string;
  CURRENT_PASSWORD_REQUIRED: string;
  NEW_PASSWORD_REQUIRED: string;
  CONFIRM_PASSWORD_REQUIRED: string;
  INVALID_PHONE: string;
  ROLE_REQUIRED: string;
  SAME_EMAIL: string;
  SAVE_FAILED: string;
  VALIDATION_FAILED: string;
  VERIFICATION_CODE_REQUIRED: string;
  VERIFICATION_CODE_LENGTH_MISMATCH: string;
  NUMBER_REQUIRED: string;
  MUST_BE_A_NUMBER: string;
  INTEGER_REQUIRED: string;
  POSITIVE_NUMBER_REQUIRED: string;
  ZERO_OR_GREATER: string;
  YEAR_LENGTH_MISMATCH: string;
  CURRENT_YEAR_OR_LESS: string;
  UPDATE_STATUS_FAILED: string;
  UPDATE_STATUS_SUCCESS: string;
  READ_PICK_DETAILS_FAILED: string;
}

interface IRoutes {
  USERS_LIST: string;
  USER_DETAILS: string;
  GROWERS_LIST: string;
  GROWER_DETAILS: string;
  GROWER_BLOCK_DETAILS: string;
  EVAL_EST_MAIN: string;
  DETAILS: string;
  EVALUATION: string;
  SIZES: string;
  ESTIMATES: string;
  MONITORING: string;
  PICK_SCHEDULE: string;
  PACK_PLAN: string;
  NEW_PICK: string;
  NEW_PICK_LIST: string;
  BLOCK: string;
  BINS: string;
  STAFF_CREW: string;
  SPECS_NOTES: string;
  LABOR: string;
  PICK_DATA: string;
  CREW_ASSIGNMENT: string;
  HARVEST_PAYROLL: string;
}

interface IPaginationParams {
  PAGE: string;
  FROM_PAGE: string;
}

export interface IConstant {
  patterns: IPattern;
  errorMessages: IErrorMessages;
  routes: IRoutes;
  appName: string;
  companyName: string;
  legalCompanyName: string;
  version: string;
  release: string;
  creationYear: number;
  paginationParams: IPaginationParams;
}

const CompanyName = 'Porterville Citrus';

export const Constants: IConstant = {
  patterns: {
    DIGIT_REGEX: /[0-9]/,
    EMAIL_REGEX:
      // eslint-disable-next-line no-useless-escape
      /^[a-z0-9!#$%&'*+\/=?^_\`{|}~.-]+@[a-z0-9]([a-z0-9-])+(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i,
    SYMBOL_REGEX: /[-+_!@#$%^&*,.?]/,
    /**
     * This pattern matches numbers with or without area code, for North America
     * (NANP format). It also has groups to enable replacing. For details, see:
     * https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s02.html
     */
    US_PHONE_REGEX: /^(?:\(?([0-9]{3})\)?[-. ]?)?([0-9]{3})[-. ]?([0-9]{4})$/,
    LOWERCASE_REGEX: /(?=.*[a-z])/,
    UPPERCASE_REGEX: /(?=.*[A-Z])/,
  },
  errorMessages: {
    COMBINATION_EXISTS: 'Combination already exists.',
    FIELD_REQUIRED: 'Field is required.',
    PASSWORD_LENGTH: 'Password must be at least 8 characters.',
    PASSWORD_LOWERCASE: 'Password must contain at least one lowercase letter.',
    PASSWORD_UPPERCASE: 'Password must contain at least one uppercase letter.',
    PASSWORD_SPECIAL_CHARACTER:
      'Password must contain at least one special character.',
    PASSWORD_NUMBER: 'Password must contain at least one number.',
    PASSWORD_MUST_MISMATCH: 'New password should not match current password.',
    PASSWORD_MUST_MATCH: 'Passwords must match.',
    EMAIL_MATCH: 'Email addresses must match.',
    FIRST_NAME_REQUIRED: 'First name is required.',
    LAST_NAME_REQUIRED: 'Last name is required.',
    EMAIL_REQUIRED: 'Email is required.',
    INVALID_EMAIL: 'Please enter a valid email address.',
    PASSWORD_REQUIRED: 'Password is required.',
    CURRENT_PASSWORD_REQUIRED: 'Current password is required.',
    NEW_PASSWORD_REQUIRED: 'New password is required.',
    CONFIRM_PASSWORD_REQUIRED: 'Confirm password is required.',
    INVALID_PHONE: 'Phone must be a North American number.',
    ROLE_REQUIRED: 'Role is required.',
    SAME_EMAIL: 'Email must be different from current value.',
    SAVE_FAILED: 'Unable to save changes. Please try again later.',
    VALIDATION_FAILED: 'Please check for missing or incorrect entries.',
    VERIFICATION_CODE_REQUIRED: 'Verification code is required.',
    VERIFICATION_CODE_LENGTH_MISMATCH:
      'Verification code must contain 6 digits.',
    NUMBER_REQUIRED: 'Field is required and must be a number.',
    MUST_BE_A_NUMBER: 'Field must be a number.',
    INTEGER_REQUIRED: 'Field must be a whole number.',
    POSITIVE_NUMBER_REQUIRED: 'Field must be greater than zero.',
    ZERO_OR_GREATER: 'Field must be zero or greater.',
    YEAR_LENGTH_MISMATCH:
      'Enter year in the form YY or YYYY, otherwise leave blank.',
    CURRENT_YEAR_OR_LESS: 'Year cannot be greater than the current year.',
    UPDATE_STATUS_FAILED:
      'Unable to update block status. Please try again later.',
    UPDATE_STATUS_SUCCESS: 'Block status updated.',
    READ_PICK_DETAILS_FAILED:
      'Unable to get Pick Details, please try again later.',
  },
  routes: {
    USERS_LIST: '/users',
    USER_DETAILS: '/users/update-user/',
    GROWERS_LIST: '/growers',
    GROWER_DETAILS: '/growers/update-grower/',
    GROWER_BLOCK_DETAILS: '/growers/update-grower-block/',
    EVAL_EST_MAIN: '/evaluations-estimates',
    DETAILS: '/details',
    EVALUATION: '/evaluations',
    SIZES: '/sizes',
    ESTIMATES: '/estimates',
    MONITORING: '/monitoring',
    PICK_SCHEDULE: '/pick-schedule',
    NEW_PICK_LIST: '/new-pick-list',
    NEW_PICK: '/new-pick',
    PACK_PLAN: '/pack-plan',
    BLOCK: '/block',
    BINS: '/bins',
    STAFF_CREW: '/staff-crew',
    SPECS_NOTES: '/specs-notes',
    LABOR: '/labor',
    PICK_DATA: '/pick-data',
    CREW_ASSIGNMENT: '/crew-assignment-wizard',
    HARVEST_PAYROLL: '/harvest-payroll',
  },
  paginationParams: {
    PAGE: 'page',
    FROM_PAGE: 'fromPage',
  },
  appName: 'Crop Management System',
  companyName: CompanyName,
  legalCompanyName: `${CompanyName}, Inc.`,
  version: environment.version,
  // Important: if the way the release string is built changes, make sure to
  // change the package.json script that uploads Sentry source maps to match.
  release: `${environment.version}-${environment.name}`,
  creationYear: 2022,
};
