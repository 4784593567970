import { GrowerBlock } from 'common/models';
import { SizeEstimate } from 'common/models/growerBlock/sizeEstimate';

export function createSizeFormValues(block: GrowerBlock): SizeEstimate[] {
  const {
    variety: { sizes },
    sizeEstimates,
  } = block;
  const newSizeEstimate: SizeEstimate[] = sizes.map(size => ({
    percentValue: 0,
    size,
  }));

  // Because the default value is an array instead of the usual object,
  // the order of the array matters to useForm(). Therefore, sorting must
  // be done here so that correct value tracking occurs.
  return [...(sizeEstimates.length > 0 ? sizeEstimates : newSizeEstimate)].sort(
    (a, b) =>
      a.id && b.id
        ? a.id - b.id
        : parseInt(a.size.value, 10) - parseInt(b.size.value, 10),
  );
}
