import { PaginatedResult, Grower } from 'common/models';
import {
  useGetAllGrowerDataQuery,
  useGetGrowersQuery,
} from 'common/api/growerApi';
import { FC, useEffect, useMemo } from 'react';
import {
  DataTable,
  DataTableFilters,
  DataTableMobile,
  DataTableProps,
} from 'common/components/DataTable';
import { usePSFQuery } from 'common/hooks';
import {
  GrowerTableItem,
  useGrowerTableData,
} from '../hooks/useGrowerTableData';
import { useGrowerTableDataMobile } from '../hooks/useGrowerTableDataMobile';
import useWindowSize from 'common/hooks/useWindowSize';
import { mobile } from 'common/styles/breakpoints';
import { handleError } from 'common/api/handleError';
import { Constants } from 'utils/constants';
import { useGetVarietiesQuery } from 'common/api/varietyApi';
import { useGetAreasQuery } from 'common/api/areaApi';
import { PSFKeys, usePSFState } from 'utils/hooks/usePSFState';
import {
  BlocksFilterKeys,
  areaFilter,
  varietyFilter,
} from 'utils/tableFilters/blockFilters';
import {
  gapStatusFilter,
  growerIdFilter,
  growerStatusFilter,
  parentGrowerFilter,
} from 'utils/tableFilters/growerFilters';
import {
  createAreaFilters,
  createVarietyFilters,
} from 'utils/tableFilters/helpers';
import { formatAllGrowerData } from '../utils/exportAllCsvUtils';
import { AsyncCsvButton } from 'common/components/CsvButton/AsyncCsvButton';
import { useRbac } from 'features/rbac';

const growersTableFilters: DataTableFilters[] = [
  parentGrowerFilter,
  growerIdFilter,
  growerStatusFilter,
  varietyFilter,
  areaFilter,
  gapStatusFilter,
];

export const GrowersTable: FC = () => {
  const { query, setQuery } = usePSFState(PSFKeys.Growers);
  const { userHasPermission } = useRbac();
  const {
    data,
    isLoading,
    isFetching,
    pagination,
    applyFilters,
    filters,
    error: getGrowersError,
  } = usePSFQuery<PaginatedResult<Grower>>(
    useGetGrowersQuery,
    {},
    { ...query },
    setQuery,
  );
  const growers = useMemo(() => data?.results ?? [], [data]);
  const { columns, data: tableData } = useGrowerTableData(growers);
  const columnsMobile = useGrowerTableDataMobile();
  const { data: varieties = [], error: varietyError } = useGetVarietiesQuery();
  const { data: areas = [], error: areaError } = useGetAreasQuery();
  const { width } = useWindowSize();
  const isMobile = width < parseInt(mobile, 10);

  const ExportAllGrowerDataBtn = () =>
    userHasPermission('export:growers') ? (
      <AsyncCsvButton<Grower[]>
        label='Export All'
        fetcher={useGetAllGrowerDataQuery}
        formatter={formatAllGrowerData}
        filenamePrefix='growers'
      />
    ) : (
      <></>
    );
  const tableProps: DataTableProps<GrowerTableItem> = {
    title: 'Growers',
    unitToPaginate: 'growers',
    isLoading,
    isFetching,
    columns: isMobile ? columnsMobile : columns,
    data: tableData,
    pagination,
    onRowClick: grower => `${Constants.routes.GROWER_DETAILS}${grower.id}`,
    filtering: {
      filterOptions: growersTableFilters,
      applyFilters,
      defaultFilters: filters,
    },
    loadError: !!getGrowersError,
    FooterComponent: ExportAllGrowerDataBtn,
  };

  useMemo(() => {
    if (!varietyError) {
      const filterIdx = growersTableFilters.findIndex(
        filter => filter.category.key === BlocksFilterKeys.varietyKey,
      );
      growersTableFilters[filterIdx].options = createVarietyFilters(varieties);
    }
  }, [varieties, varietyError]);

  useMemo(() => {
    if (!areaError) {
      const filterIndex = growersTableFilters.findIndex(
        filter => filter.category.key === BlocksFilterKeys.areaKey,
      );
      growersTableFilters[filterIndex].options = createAreaFilters(areas);
    }
  }, [areas, areaError]);

  useEffect(() => {
    if (getGrowersError) {
      handleError(getGrowersError, 'Unable to load growers');
    }
  }, [getGrowersError]);

  return (
    <>
      {isMobile ? (
        <DataTableMobile<GrowerTableItem> {...tableProps} />
      ) : (
        <>
          <DataTable<GrowerTableItem> {...tableProps} />
        </>
      )}
    </>
  );
};
