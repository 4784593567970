import { TableContainer } from 'common/components/DataTable';
import { DetailFormContext } from 'common/components/DetailControls/DetailForm';
import { WithLoadingOverlay } from 'common/components/LoadingSpinner';
import { CmsDate } from 'common/models/cmsDate';
import { EstimateLog } from 'common/models/growerBlock/estimateLog';
import * as notificationService from 'common/services/notification';
import { mobile } from 'common/styles/breakpoints';
import { blueShadeHeader, darkNavy, lighterNavy } from 'common/styles/colors';
import { FormPadding } from 'common/styles/form';
import { BlockInfo } from 'features/grower-views/components/BlockDetailsForm';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';

const TableWrapper = styled.div`
  margin: ${FormPadding};

  & > div {
    box-shadow: none;
    border-style: solid;
    border: solid 2px ${blueShadeHeader};
  }

  @media screen and (max-width: ${mobile}) {
    margin: ${FormPadding} 2px;
    & > div {
      padding-left: 2px;
      padding-right: 2px;
    }
    table {
      table-layout: fixed;
    }
    tr {
      & > *:first-child {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }
      & > *:nth-child(2) {
        width: 45px;
      }
      & > *:nth-child(3) {
        width: 100px;
      }
    }
  }

  tr > th:last-of-type,
  td:last-of-type {
    text-align: right;
  }

  tr > th,
  td {
    color: ${darkNavy};
    width: 33%;
  }

  tr > td:nth-child(2) {
    font-weight: 600;
    text-align: center;
  }

  tr > th:nth-child(2) {
    text-align: center;
  }
`;

const DropDownWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const EstimateHistoryDropDown = styled.label`
  display: flex;
  align-items: center;

  select {
    color: ${lighterNavy};
    border-radius: 4px;
    border-color: ${darkNavy};
    padding: 0 25px 0 10px;
    margin-left: 0.5em;
    width: 100%;
    cursor: pointer;

    /* Dropdown selector icon */
    appearance: none;
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%);
    background-position:
      calc(100% - 14px) calc(11px),
      calc(100% - 9px) calc(11px);
    background-size:
      5px 5px,
      5px 5px,
      1px 1.5em;
    background-repeat: no-repeat;
  }
`;

const TableHeader = styled.div`
  display: flex;
  margin-bottom: ${FormPadding};
  justify-content: space-between;
`;

interface HistoryTableProps {
  conversionRate: number | undefined | null;
  estimates: EstimateLog[] | undefined;
}

type EstimateLogItem = Omit<EstimateLog, 'createdAt' | 'createdByUser'> & {
  createdAt: CmsDate | null;
  year: number | undefined;
  createdBy: string;
};

export const EstimatesHistoryTable: FC<HistoryTableProps> = ({
  conversionRate,
  estimates,
}) => {
  const [isBin, setIsBin] = useState<boolean>(true);
  const [hasConversionRate, setHasConversionRate] = useState<boolean>(true);
  const [filterYear, setFilterYear] = useState<number>(0);

  const estimateItems = useMemo(
    () =>
      (estimates ?? []).reduce(
        (acc, estimate, idx, log) => {
          const isInitial = idx === log.length - 1;
          const createdAt = CmsDate.parse(estimate.createdAt);
          const createdBy = estimate.createdByUser
            ? `${estimate.createdByUser.firstName} ${estimate.createdByUser.lastName}`
            : '';
          const item = {
            ...estimate,
            createdAt,
            year: createdAt?.toDate().getFullYear(),
            createdBy,
          };

          if (isInitial) {
            acc.initial = item;
          } else {
            acc.history.push(item);
          }

          if (item.year) {
            acc.years.add(item.year);
          }

          return acc;
        },
        {
          initial: null as EstimateLogItem | null,
          history: [] as EstimateLogItem[],
          years: new Set<number>([0]), // Use 0 as a default, to show all years.
        },
      ),
    [estimates],
  );

  const hasEstimateYear = estimateItems.years.size > 0;

  const [filteredEstimates, setFilteredEstimates] = useState<EstimateLogItem[]>(
    estimateItems.history,
  );

  const filterOnYear = useCallback(
    (year: number) => {
      if (year === 0) {
        setFilteredEstimates(estimateItems.history);
        return;
      }

      setFilteredEstimates(
        estimateItems.history.filter(estimate => estimate.year === year),
      );
    },
    [estimateItems.history],
  );

  // Allows adding an estimate and seeing it reflected in the history.
  useEffect(() => {
    filterOnYear(filterYear);
  }, [filterOnYear, filterYear]);

  const handleChangeYear = (year: number) => {
    setFilterYear(year);
    filterOnYear(filterYear);
  };

  const handleConversionClick = () => {
    if (!Number(conversionRate)) {
      setHasConversionRate(false);
      notificationService.showErrorMessage('Conversion rate not available.');
    }
  };

  const editContext = useContext(DetailFormContext);

  return (
    <WithLoadingOverlay isLoading={!estimates}>
      <TableWrapper>
        <TableContainer>
          <TableHeader>
            <BlockInfo>Estimate History</BlockInfo>
            <DropDownWrapper>
              <EstimateHistoryDropDown htmlFor='unitSelector'>
                Unit
                <select
                  disabled={
                    hasConversionRate && !editContext.editable
                      ? undefined
                      : true
                  }
                  onClick={handleConversionClick}
                  onChange={() => setIsBin(!isBin)}
                  name='unitSelector'
                  id='unitSelector'
                >
                  <option value='bins'>Bin</option>
                  <option value='cartons'>Carton</option>
                </select>
              </EstimateHistoryDropDown>
              <EstimateHistoryDropDown htmlFor='yearSelector'>
                Year
                <select
                  disabled={!hasEstimateYear || editContext.editable}
                  onChange={e => handleChangeYear(Number(e.target.value))}
                  name='yearSelector'
                  id='yearSelector'
                >
                  {Array.from(estimateItems.years).map(year => (
                    <option value={year} key={year}>
                      {year === 0 ? 'All' : year}
                    </option>
                  ))}
                </select>
              </EstimateHistoryDropDown>
            </DropDownWrapper>
          </TableHeader>
          <table>
            <thead>
              <tr>
                <th>
                  {estimateItems.initial
                    ? `${estimateItems.initial.createdBy} (initial estimate)`
                    : 'NONE'}
                </th>
                {estimateItems.initial && (
                  <th>
                    {!isBin && conversionRate
                      ? conversionRate * estimateItems.initial.estimatedBins
                      : estimateItems.initial.estimatedBins}
                  </th>
                )}
                <th>
                  {estimateItems.initial?.createdAt &&
                    estimateItems.initial.createdAt.toDateString()}
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredEstimates?.map((row, idx) => {
                return (
                  <tr
                    key={`${row.createdAt?.toISO()}${row.estimatedBins + idx}`}
                    className={`${
                      idx % 2 ? 'blue-row' : ''
                    } table-component-row`}
                  >
                    <td>{row.createdBy}</td>
                    <td>
                      {!isBin && conversionRate
                        ? conversionRate * row?.estimatedBins
                        : row?.estimatedBins}
                    </td>
                    <td>{row?.createdAt?.toDateString()}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </TableContainer>
      </TableWrapper>
    </WithLoadingOverlay>
  );
};
