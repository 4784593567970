import WithTooltip from 'common/components/WithTooltip/WithTooltip';
import { BlockStatus, GrowerStatus } from 'common/models';
import {
  darkerBlueShade,
  green,
  tableHeaderGrey,
  orange,
  red,
  brightOrange,
} from 'common/styles/colors';
import { ReactNode } from 'react';
import NewIcon from '@mui/icons-material/AddBox';
import { ReactComponent as ActiveIcon } from 'assets/img/grower-block-active-icon.svg';
import NonProducingIcon from '@mui/icons-material/Pending';
import InactiveIcon from '@mui/icons-material/RemoveCircle';
import PendingIcon from '@mui/icons-material/AddBoxOutlined';
import WithdrawnIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import { ReactComponent as FruitCrateIcon } from 'assets/img/fruit-crate.svg';
import styled from 'styled-components';
import { getEnumLabels, toEnumLabel } from 'utils/enumFunctions';

const StyledFruitCrate = styled(FruitCrateIcon)`
  margin: 0 3px 0 2px;
  fill: ${orange};
`;

export const useStatus = () => {
  /** Provides the color, text and icon of a given grower or block status. */
  const createStatusProps = (
    status: number,
    type: 'grower' | 'block',
    withTooltip: boolean,
  ): {
    color: string;
    text: string;
    icon: ReactNode;
  } => {
    const statusStr =
      type === 'grower'
        ? toEnumLabel(GrowerStatus[status])
        : toEnumLabel(BlockStatus[status]);

    // Use the block status keys over grower status keys as there are more.
    const [
      noStatusStr,
      newStr,
      pendingStr,
      activeStr,
      nonProdStr,
      withdrawnStr,
      harvestedStr,
      inactiveStr,
    ] = getEnumLabels(BlockStatus);

    let color: string;
    let text: string;
    let icon = <></>;

    switch (statusStr) {
      case newStr:
        color = `${green}`;
        text = `${newStr}`;
        icon = <NewIcon sx={{ color: `${green}` }} />;
        break;

      case activeStr:
        color = `${darkerBlueShade}`;
        text = `${activeStr}`;
        icon = <ActiveIcon />;
        break;

      case pendingStr:
        color = `${green}`;
        text = `${pendingStr}`;
        icon = <PendingIcon sx={{ color: `${green}` }} />;
        break;

      case nonProdStr:
        color = `${tableHeaderGrey}`;
        text = `${nonProdStr}`;
        icon = <NonProducingIcon sx={{ color: `${tableHeaderGrey}` }} />;
        break;

      case withdrawnStr:
        color = `${red}`;
        text = `${withdrawnStr}`;
        icon = <WithdrawnIcon sx={{ color: `${red}` }} />;
        break;

      case harvestedStr:
        color = `${orange}`;
        text = `${harvestedStr}`;
        icon = <StyledFruitCrate />;
        break;

      case inactiveStr:
        color = `${tableHeaderGrey}`;
        text = `${inactiveStr}`;
        icon = <InactiveIcon sx={{ color: `${tableHeaderGrey}` }} />;
        break;

      case noStatusStr:
      default:
        color = `${brightOrange}`;
        text = `(${noStatusStr})`;
        icon = <ErrorIcon htmlColor={brightOrange} />;
    }

    icon = withTooltip ? (
      <WithTooltip title={`${text} Icon`} tooltipText={`${text}`}>
        {icon}
      </WithTooltip>
    ) : (
      icon
    );

    return { color, text, icon };
  };

  return {
    createStatusProps,
  };
};
