import { mobile } from 'common/styles/breakpoints';
import { darkNavy } from 'common/styles/colors';
import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { ToggleModalButton } from '../BlockDetailsFooter';

const ButtonTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  span {
    color: ${darkNavy};
    font-family: 'KanitLight';
    font-size: 16px;
    line-height: 105%;
    letter-spacing: 0.0075em;
    margin-left: 10px;
  }

  @media (max-width: ${mobile}) {
    flex-direction: column;

    span {
      margin: 5px 0 0;
      text-align: center;
    }
  }
`;

interface Props {
  openModal: Dispatch<SetStateAction<boolean>>;
}

export const MakeInactiveButton: React.FC<Props> = ({ openModal }) => {
  return (
    <ButtonTextWrapper>
      <ToggleModalButton
        type='button'
        className='make-inactive-btn'
        onClick={() => openModal(true)}
      >
        MAKE INACTIVE
      </ToggleModalButton>
      <span>
        If this block will not be harvested this season or is being withdrawn
      </span>
    </ButtonTextWrapper>
  );
};
