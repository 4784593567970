import { yupResolver } from '@hookform/resolvers/yup';
import { handleError } from 'common/api/handleError';
import { BaseTableItem } from 'common/components/GenericTable';
import {
  DetailDropDown,
  DetailTable,
  DetailText,
  Option,
  DetailTextArea,
} from 'common/components/DetailControls';
import { DetailForm } from 'common/components/DetailControls/DetailForm';
import FormPrompt from 'common/components/FormPrompt';
import { InitialsAvatar } from 'common/components/UserCard';
import useWindowSize from 'common/hooks/useWindowSize';
import { GrowerBlock, Role, RoleType, User } from 'common/models';
import * as notifier from 'common/services/notification';
import { mobile } from 'common/styles/breakpoints';
import { lightGreyText, red, secondaryWhite } from 'common/styles/colors';
import { MobileScrollWrapper } from 'common/styles/page';
import { useAuth } from 'features/auth/hooks';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Constants } from 'utils/constants';
import * as yup from 'yup';
import { UserActionWrapper } from './UserActionWrapper';

const StyledAvatar = styled(InitialsAvatar)`
  color: ${secondaryWhite};
  margin: 0 auto 10px auto;
  font-size: 48px;
  font-weight: bold;
  width: 72px;
  height: 72px;
`;

const HeaderBanner = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${red};
  text-transform: uppercase;
  color: ${lightGreyText};
  font-family: KanitRegular;
  font-size: 18px;
  padding: 3px 0;
  margin-bottom: 10px;

  @media (max-width: ${mobile}) {
    border-radius: 0;
  }
`;

/**
 * The properties accepted by the {@link UserDetailForm}.
 */
interface UserDetailFormProps {
  /** The object representing the user to view or edit. */
  user?: User;

  /** The list of all roles available in the system. */
  availableRoles: Role[];

  /** The function that will actually update user data in the backend. */
  updater: (usr: UserDetails) => Promise<void>;
  closeHandler: () => void;
}

/**
 * The type of object used to display grower blocks in the detail table.
 */
type GrowerBlockItem = BaseTableItem & {
  name: string;
  variety: string;
};

/**
 * The type of data bound to the form via the {@link useForm} hook.
 */
export type UserDetails = Pick<
  User,
  'firstName' | 'lastName' | 'role' | 'email' | 'phone' | 'notes'
>;

/**
 * Converts a role to an {@link Option} for use in the detail dropdown.
 *
 * @param role The role to convert.
 */
function toOption(role: Role): Option {
  return { id: role.id, label: role.roleName };
}

/**
 * Converts a dropdown option to a {@link Role} the server can understand.
 *
 * @param opt The option to convert.
 */
function toRole(opt: Option): Role {
  return { id: opt.id, roleName: opt.label as RoleType };
}

/** The schema used to validate user details. */
const userSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .required(Constants.errorMessages.FIRST_NAME_REQUIRED),
  lastName: yup
    .string()
    .trim()
    .required(Constants.errorMessages.LAST_NAME_REQUIRED),
  role: yup.object({
    roleName: yup.string().required(Constants.errorMessages.ROLE_REQUIRED),
  }),
  email: yup
    .string()
    .email(Constants.errorMessages.INVALID_EMAIL)
    .required(Constants.errorMessages.EMAIL_REQUIRED),
  phone: yup.string().nullable().matches(Constants.patterns.US_PHONE_REGEX, {
    message: Constants.errorMessages.INVALID_PHONE,
    excludeEmptyString: true,
  }),
  notes: yup.string().nullable().max(10000),
});

export const UserDetailForm: FC<UserDetailFormProps> = ({
  user,
  availableRoles,
  updater,
  closeHandler,
}) => {
  const { width } = useWindowSize();
  const isMobile = width < parseInt(mobile, 10);
  const { user: loggedInUser } = useAuth();

  const getRoleOptions = () => availableRoles.map(toOption);

  /**
   * Placeholder for block data that will be returned in a separate call.
   *
   * @remark - Previously, blocks were attached to the GET `/users/[id]` request.
   * But because of the large number of blocks returned per user, a paginated call
   * will need to be implemented instead.
   * */
  const data: { blocks: GrowerBlock[] | undefined } = { blocks: undefined };

  const formatUserBlocks = (): GrowerBlockItem[] =>
    data?.blocks
      ? data?.blocks.map(blk => {
          return {
            id: blk.blockId,
            name: blk.blockName,
            // Use conditional chaining here as a variety may not have been set yet.
            variety: blk?.variety?.varietyName,
          };
        })
      : [];

  const roleOptions = useMemo(getRoleOptions, [availableRoles]);
  const blocks = useMemo(formatUserBlocks, [data.blocks]);
  const methods = useForm<UserDetails>({
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      role: user?.role,
      email: user?.email,
      phone: user?.phone,
      notes: user?.notes,
    },
    resolver: yupResolver(userSchema),
    mode: 'all',
  });
  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting, isSubmitSuccessful, isDirty, isValid, errors },
    reset,
    getValues,
  } = methods;
  // The role dropdown binds using a Controller, so it has no need for 'ref'.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { ref, ...roleRegistrationProps } = register('role');

  // This function saves changes, if needed, handling errors in the process to
  // inform the user and to update the form's editable mode.
  const trySubmit = useCallback(
    async (evt: React.FormEvent) => {
      let isSuccessful: boolean;

      if (isDirty && isValid) {
        try {
          await handleSubmit(updater)(evt);
          notifier.showSuccessMessage('User updated.');
          isSuccessful = true;
        } catch (error) {
          handleError(error, Constants.errorMessages.SAVE_FAILED);
          isSuccessful = false;
        }
      } else if (!isValid) {
        notifier.showErrorMessage(Constants.errorMessages.VALIDATION_FAILED);
        isSuccessful = false;
      } else {
        isSuccessful = true; // No changes, continue normally.
      }

      return isSuccessful;
    },
    [handleSubmit, updater, isDirty, isValid],
  );

  // This effect clears the dirty flag.
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getValues());
    }
  }, [isSubmitSuccessful, reset, getValues]);

  // Creates a scrolling wrap on mobile devices
  const MobileWrapper = isMobile ? MobileScrollWrapper : React.Fragment;

  return (
    <FormProvider<UserDetails> {...methods}>
      <DetailForm
        submitAction={trySubmit}
        disabled={isSubmitting}
        reset={reset}
        closeHandler={closeHandler}
      >
        <MobileWrapper>
          {user?.activatedAt ? (
            <StyledAvatar name={user?.firstName} />
          ) : (
            <HeaderBanner>Account not activated yet</HeaderBanner>
          )}
          <DetailText
            label='First name'
            name='firstName'
            validation={errors.firstName?.message}
          />
          <DetailText
            label='Last name'
            name='lastName'
            validation={errors.lastName?.message}
          />
          {loggedInUser?.id !== user?.id && (
            <DetailDropDown
              label='Role'
              defaultValue={
                user?.role
                  ? ({
                      id: user.role.id,
                      label: user.role.roleName,
                    } as Option)
                  : null
              }
              textValue={user?.role?.roleName || ''}
              options={roleOptions}
              optionTransformer={toRole}
              {...roleRegistrationProps}
              formControl={control}
            />
          )}
          <DetailText
            label='Email'
            name='email'
            validation={errors.email?.message}
          />
          <DetailText
            label='Phone'
            name='phone'
            validation={errors.phone?.message}
          />
          <DetailTextArea
            label='Notes'
            name='notes'
            validation={errors.notes?.message}
          />
          {data?.blocks && user?.role.roleName === 'Field Representative' && (
            <DetailTable
              label='Blocks'
              items={blocks}
              emptyMessage='No assigned blocks.'
            />
          )}
          <FormPrompt isDirty={isDirty} isSubmitting={isSubmitting} />
        </MobileWrapper>
        <UserActionWrapper user={user} />
      </DetailForm>
    </FormProvider>
  );
};
