import { DetailControl } from 'common/components/DetailControls/DetailControl';
import { borderGrey, lighterNavy } from 'common/styles/colors';
import { useContext } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import { DetailFormContext } from './DetailForm';
import { applyErrorBorder } from 'common/styles/form';

// The following import is actually used by a doc link.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type GrowerDetailForm from 'features/grower-views/components/GrowerDetailForm';
import { Form } from 'react-bootstrap';

interface DetailDatePickerProps {
  control: unknown;

  /**
   * The placeholder text for the Date Picker.
   */
  placeholderText?: string;

  /** The name of the data field the control is bound to. */
  name: string;

  /**
   * Whether the field is editable when the form is in edit mode.
   */
  editable?: boolean;

  /**
   * The label that renders before the input.
   */
  label: string;
}

const StyledDatePicker = styled(ReactDatePicker)<{
  enabled?: boolean;
  $isError: boolean;
}>`
  font-family: KanitSemiBold;
  color: ${lighterNavy};
  height: 100%;
  width: 100%;
  border: ${props => (props.enabled ? `1px solid ${borderGrey}` : 'none')};
  border-radius: 0.25rem;
  padding: 0.43rem ${props => (props.enabled ? '0.5rem' : '0')};
  ${props => applyErrorBorder(props.$isError)}

  // Needed to prevent Safari from changing the color of (disabled) text.
  opacity: 1;
  -webkit-text-fill-color: ${lighterNavy};
`;

/**
 * Creates an input control that shows a pop up date selector on focus.
 * It leverages the `react-datepicker` package.
 *
 * @param props The properties required by the component.
 *
 * @remarks
 * This control will not update as expected in read-only mode.
 * Use the DetailText component when the date is read-only.
 * Look at the `gapUpdatedAt` prop
 * on the {@link GrowerDetailForm} for an example.
 */
const DetailDatePicker: React.FC<DetailDatePickerProps> = ({
  name,
  control,
  placeholderText = 'Select...',
  editable,
  label,
}) => {
  const context = useContext(DetailFormContext);
  const isDisabled = !context.editable || editable === false;

  return (
    <DetailControl
      label={label}
      valueRenderer={id => (
        <>
          <Controller
            name={name}
            control={control as never}
            render={({ field: { onChange, value }, formState: { errors } }) => (
              <>
                <StyledDatePicker
                  id={id}
                  placeholderText={placeholderText}
                  selected={value && new Date(value)}
                  onChange={onChange}
                  disabled={isDisabled}
                  enabled={!isDisabled}
                  $isError={!!errors[`${name}`]}
                />
                {/* The error Feedback control does not work without this hidden Select */}
                <Form.Select hidden isInvalid={!!errors[`${name}`]} />
                {errors[`${name}`] ? (
                  <Form.Control.Feedback type='invalid'>
                    <>{errors[`${name}`]?.message}</>
                  </Form.Control.Feedback>
                ) : null}
              </>
            )}
          />
        </>
      )}
    />
  );
};

export default DetailDatePicker;
