import { WizardDropdownOption } from 'common/components/WizardControls/WizardDropdown';
import { Contractor } from 'common/models';

export const createContractorOptions = (
  contractors: Contractor[] | undefined,
): WizardDropdownOption<Contractor>[] =>
  contractors
    ?.filter(contractor => contractor.contractorName !== 'TBA')
    ?.map(contractor => ({
      label: contractor.code,
      value: contractor,
    }))
    ?.sort((a, b) => a.label.localeCompare(b.label)) || [];
