import {
  darkNavy,
  lighterNavy,
  darkOrange,
  grey,
  orange,
  white,
  tableHeaderGrey,
  tableBodyGrey,
} from 'common/styles/colors';
import { FC, useCallback } from 'react';
import styled from 'styled-components';
import { Close } from '@mui/icons-material';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { handleError } from 'common/api/handleError';
import * as notifier from 'common/services/notification';
import { Constants } from 'utils/constants';
import { useUpdateBlockStatusMutation } from 'common/api/growerBlockApi';
import { useParams } from 'react-router-dom';
import { BlockStatus, ReasonEnum } from 'common/models';
import { UpdateBlockStatusRequest } from 'common/api/growerBlockApiTypes';
import { toEnumLabel } from 'utils/enumFunctions';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 20px 20px;
`;

const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 16px;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: ${tableBodyGrey};

  & div {
    height: 2px;
    background-color: ${grey};
  }

  & label {
    display: flex;
    justify-content: start;
    align-items: center;
    font-family: KanitLight;

    & input[type='radio'] {
      width: 19px;
      height: 19px;
      margin-right: 8px;
      appearance: none; // turn off default styling
      cursor: pointer;
      padding: 4px;
      border-radius: 50%;
      border: 3px solid ${white};
      outline: 2px solid ${grey};
    }

    & input[type='radio']:hover {
      background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25));
    }

    & input[type='radio']:checked {
      background-color: ${lighterNavy};
    }

    & input[type='text'] {
      padding: 4px;
      color: ${darkNavy};
      margin-left: 1.75rem;
      border-radius: 4px;
      border: 1px solid ${darkNavy};
      width: 72%;
    }

    & span {
      font-weight: 600;
    }
  }

  & label:last-of-type {
    font-weight: 100;
    font-family: KanitLight;
    color: ${darkNavy};
    margin: 0.25rem 0;
  }

  .invalid-feedback {
    height: auto;
    background: none;
    font-size: 1rem;
    border-top: solid 2px red;
  }
`;

const ButtonDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;

  & button {
    padding: 6.5px 20px;
    color: ${white};
    background-color: ${orange};
    border: none;
    border-radius: 4px;

    &:hover {
      background-color: ${darkOrange};
    }

    & span {
      font-family: KanitRegular;
      font-size: 18px;
      line-height: 148%;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.12em;
    }
  }

  & button:last-of-type {
    background: none;
    padding: 1px;
    display: flex;
    align-items: center;
    color: ${tableHeaderGrey};
  }

  & button:last-of-type:hover {
    color: ${darkNavy};
  }
`;

/**
 * The type of data bound to the form via the {@link useForm} hook.
 */
export type FormData = {
  reason: number;
  note?: string;
};

const defaults = {
  reason: undefined,
  note: '',
};

interface Props {
  onClose: (evt: unknown) => void;
  setLoading: (newState: boolean) => void;
}

const BlockInactiveForm: FC<Props> = ({ onClose, setLoading }) => {
  const { id } = useParams<{ id: string }>();
  const [updateBlockStatus] = useUpdateBlockStatusMutation();

  const schema = yup.object({
    reason: yup.number().integer().required(),
    notes: yup.string().trim(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<FormData>({
    defaultValues: defaults,
    resolver: yupResolver(schema),
  });

  const updater = useCallback(
    async (data: FormData, evt: unknown) => {
      if (!isDirty) return;
      const inactiveReason: UpdateBlockStatusRequest = {
        ...data,
        blockStatus: BlockStatus.Withdrawn,
        id: parseInt(id, 10),
      };
      if (inactiveReason.reason === ReasonEnum.No_Harvest)
        inactiveReason.blockStatus = BlockStatus.Non$Producing;
      try {
        setLoading(true);
        await updateBlockStatus(inactiveReason).unwrap();
        notifier.showSuccessMessage(
          Constants.errorMessages.UPDATE_STATUS_SUCCESS,
        );
        onClose(evt);
        setLoading(false);
      } catch (error) {
        handleError(error, Constants.errorMessages.UPDATE_STATUS_FAILED);
        setLoading(false);
      }
    },
    [id, isDirty, onClose, setLoading, updateBlockStatus],
  );

  const onError = () => {
    notifier.showErrorMessage(Constants.errorMessages.VALIDATION_FAILED);
  };

  const withdrawnReasons = [
    {
      enumValue: ReasonEnum.Moved_Packer,
      reasonString: toEnumLabel(ReasonEnum[ReasonEnum.Moved_Packer]),
    },
    {
      enumValue: ReasonEnum.PCI_Release,
      reasonString: toEnumLabel(ReasonEnum[ReasonEnum.PCI_Release]),
    },
    {
      enumValue: ReasonEnum.Ranch_Sold,
      reasonString: toEnumLabel(ReasonEnum[ReasonEnum.Ranch_Sold]),
    },
    {
      enumValue: ReasonEnum.Redeveloped,
      reasonString: toEnumLabel(ReasonEnum[ReasonEnum.Redeveloped]),
    },
    {
      enumValue: ReasonEnum.Other,
      reasonString: toEnumLabel(ReasonEnum[ReasonEnum.Other]),
    },
  ];

  return (
    <StyledForm onSubmit={handleSubmit(updater, onError)}>
      <InputDiv>
        <label htmlFor='Non-producing'>
          <input
            type='radio'
            value={ReasonEnum.No_Harvest}
            {...register('reason', { required: true })}
          />
          <span style={{ marginRight: '0.35rem' }}>{`${toEnumLabel(
            BlockStatus[BlockStatus.Non$Producing],
          )} -`}</span>
          No harvest this season
        </label>
        <div />
        {withdrawnReasons.map(reason => {
          return (
            <label
              key={reason.reasonString}
              htmlFor={`Withdrawn${reason.enumValue}`}
            >
              <input
                type='radio'
                value={reason.enumValue}
                {...register('reason', { required: true })}
              />
              <span style={{ marginRight: '0.35rem' }}>Withdrawn -</span>
              {`${reason.reasonString}`}
            </label>
          );
        })}
        <div
          className='invalid-feedback'
          style={{
            display: errors.reason?.message ? 'block' : 'none',
          }}
        >
          A reason must be selected
        </div>
        <label htmlFor='note'>
          Add a note
          <input
            type='text'
            maxLength={255}
            placeholder='Notes'
            {...register('note')}
          />
        </label>
      </InputDiv>
      <ButtonDiv>
        <button type='submit'>
          <span>CONFIRM INACTIVE</span>
        </button>
        <button type='button' onClick={onClose}>
          <span>Cancel</span>
          <Close />
        </button>
      </ButtonDiv>
    </StyledForm>
  );
};

export default BlockInactiveForm;
