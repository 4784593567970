import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import citrusTreeBackground from '../../../../assets/img/citrus-bg.png';
import { lightGreyText } from 'common/styles/colors';
import LogoHeader from 'features/auth/components/LogoHeader';
import { mobile } from 'common/styles/breakpoints';

const ViewContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(180deg, #112457 0%, #10523a 100%);
  color: ${lightGreyText};
`;

const ContentContainer = styled.div`
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
  max-width: 1060px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BackgroundImage = styled.div`
  background-image: url(${citrusTreeBackground});
  background-size: cover;
  height: 16vh;
  width: 100%;
  margin-bottom: 30px;
`;

const ChildWrapper = styled.div`
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: clamp(300px, 80vw, 650px);

  @media (max-width: ${mobile}) {
    align-items: center;
    margin: 23px 0 100px;
  }
`;

export const StyledContainer = styled.div`
  width: 100%;
`;

type NoTokenProps = {
  children?: ReactNode;
};

const NoTokenLayout: FC<NoTokenProps> = ({ children }) => {
  return (
    <ViewContainer>
      <BackgroundImage />
      <ContentContainer>
        <LogoHeader />
        <ChildWrapper>{children}</ChildWrapper>
      </ContentContainer>
    </ViewContainer>
  );
};

export default NoTokenLayout;
