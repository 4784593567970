import { useGetBlocksToScheduleQuery } from 'common/api/growerBlockApi';
import { handleError } from 'common/api/handleError';
import { DataTable, DataTableProps } from 'common/components/DataTable';
import { usePSFQuery } from 'common/hooks';
import { PaginatedResult } from 'common/models';
import { Dispatch, FC, SetStateAction, useEffect, useMemo } from 'react';
import { growerIdFilter } from 'utils/tableFilters/growerFilters';
import { PSFKeys, usePSFState } from 'utils/hooks/usePSFState';
import {
  AvailBlocksTableItem,
  useAvailBlocksTableData,
} from '../hooks/useAvailBlocksTableData';
import { AvailBlockToPick } from '../types';
import { DateWithoutTime } from 'common/models/dateType';

export const AvailableBlocksTable: FC<{
  varietyId: number;
  pickDay: DateWithoutTime;
  packHouseId: number;
  setBlocks: Dispatch<SetStateAction<AvailBlockToPick[]>>;
}> = ({ varietyId, pickDay, packHouseId, setBlocks }) => {
  const { query, setQuery } = usePSFState(PSFKeys.SchedulableBlocks);
  const {
    data,
    isLoading,
    isFetching,
    pagination,
    filters,
    applyFilters,
    error: getBlocksError,
  } = usePSFQuery<PaginatedResult<AvailBlockToPick>>(
    useGetBlocksToScheduleQuery,
    {},
    { ...query },
    setQuery,
    { varietyId, pickDay, packHouseId },
  );
  const blocks = useMemo(() => data?.results ?? [], [data]);
  const { columns, data: tableData } = useAvailBlocksTableData(
    setBlocks,
    blocks,
  );

  const tableProps: DataTableProps<AvailBlocksTableItem> = {
    unitToPaginate: 'Blocks',
    isLoading,
    isFetching,
    columns,
    data: tableData,
    pagination,
    filtering: {
      filterOptions: [growerIdFilter],
      applyFilters,
      defaultFilters: filters,
    },
    loadError: !!getBlocksError,
  };

  useEffect(() => {
    if (getBlocksError)
      handleError(getBlocksError, 'Unable to load blocks to schedule.');
  }, [getBlocksError]);

  return <DataTable<AvailBlocksTableItem> {...tableProps} />;
};
