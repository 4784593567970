import { CsvHeaders } from 'common/components/CsvButton/CsvButton';
import { Grower } from 'common/models';
import { CmsDate } from 'common/models/cmsDate';

const enum GrowerKey {
  growerId = 'growerId',
  name = 'name',
  parentName = 'parentName',
  manager = 'manager',
  managerCell = 'managerCell',
  grpNumber = 'grpNumber',
  permitNumber = 'permitNumber',
  skNumber = 'skNumber',
  ggnNumber = 'ggnNumber',
  certExp = 'certExp',
  gapStatus = 'gapStatus',
  gapUpdatedAt = 'gapUpdatedAt',
  address1 = 'address1',
  address2 = 'address2',
  city = 'city',
  state = 'state',
  zip = 'zip',
  phone = 'phone',
  email = 'email',
}

const growerLabels: Record<GrowerKey, string> = {
  growerId: 'Grower ID',
  name: 'Grower Name',
  parentName: 'Parent Grower Name',
  manager: 'Manager Name',
  managerCell: 'Manager Cell',
  grpNumber: 'GRP #',
  permitNumber: 'Permit #',
  skNumber: 'SK #',
  ggnNumber: 'GGN #',
  certExp: 'Cert. Expiration',
  gapStatus: 'GAP Status',
  gapUpdatedAt: 'GAP Updated At',
  address1: 'Address Line 1',
  address2: 'Address Line 2',
  city: 'City',
  state: 'State',
  zip: 'Zip',
  phone: 'Phone',
  email: 'Email',
};

const blockDetailHeaders: CsvHeaders<GrowerKey>[] = [
  { label: growerLabels.growerId, key: GrowerKey.growerId },
  { label: growerLabels.name, key: GrowerKey.name },
  { label: growerLabels.parentName, key: GrowerKey.parentName },
  { label: growerLabels.manager, key: GrowerKey.manager },
  { label: growerLabels.managerCell, key: GrowerKey.managerCell },
  { label: growerLabels.grpNumber, key: GrowerKey.grpNumber },
  { label: growerLabels.permitNumber, key: GrowerKey.permitNumber },
  { label: growerLabels.skNumber, key: GrowerKey.skNumber },
  { label: growerLabels.ggnNumber, key: GrowerKey.ggnNumber },
  { label: growerLabels.certExp, key: GrowerKey.certExp },
  { label: growerLabels.gapStatus, key: GrowerKey.gapStatus },
  { label: growerLabels.gapUpdatedAt, key: GrowerKey.gapUpdatedAt },
  { label: growerLabels.address1, key: GrowerKey.address1 },
  { label: growerLabels.address2, key: GrowerKey.address2 },
  { label: growerLabels.city, key: GrowerKey.city },
  { label: growerLabels.state, key: GrowerKey.state },
  { label: growerLabels.zip, key: GrowerKey.zip },
  { label: growerLabels.phone, key: GrowerKey.phone },
  { label: growerLabels.email, key: GrowerKey.email },
];

/**
 * Returns csv formated headers and rows for all grower data.
 */
export const formatAllGrowerData = (
  data: Grower[],
): {
  headers: CsvHeaders<GrowerKey>[];
  rows: Record<GrowerKey, string>[];
} => ({
  headers: blockDetailHeaders,
  rows: data.map(
    ({
      growerId,
      name,
      parentGrowerName,
      managerName,
      managerCell,
      grpNumber,
      permitNumber,
      ggnNumber,
      certExp,
      gapStatus,
      gapUpdatedAt,
      skNumber,
      address,
      phone,
      email,
    }) => {
      const certDate = CmsDate.parse(certExp)?.toString();
      const gapDate = CmsDate.parse(gapUpdatedAt)?.toString();

      return {
        growerId: growerId ?? '',
        name: name ?? '',
        parentName: parentGrowerName ?? '',
        manager: managerName ?? '',
        managerCell: managerCell ?? '',
        grpNumber: grpNumber?.toString() ?? '',
        permitNumber: permitNumber?.toString() ?? '',
        ggnNumber: ggnNumber?.toString() ?? '',
        certExp: certDate ?? '',
        gapStatus: gapStatus ?? '',
        gapUpdatedAt: gapDate ?? '',
        skNumber: skNumber ?? '',
        address1: address?.addressLine1 ?? '',
        address2: address?.addressLine2 ?? '',
        city: address?.city ?? '',
        state: address?.state ?? '',
        zip: address?.zipCode ?? '',
        phone: phone ?? '',
        email: email ?? '',
      };
    },
  ),
});
