import { WithLoadingOverlay } from 'common/components/LoadingSpinner';
import { LoginButton } from 'common/styles/button';
import { green, tableBodyGrey } from 'common/styles/colors';
import { useRbac } from 'features/rbac';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { ApproveScheduleModal } from './ApproveScheduleModal';
import { useApproveScheduleMutation } from 'common/api/pickSchedulingApi';
import { handleError } from 'common/api/handleError';
import * as notifier from 'common/services/notification';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { mobile } from 'common/styles/breakpoints';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import { useAuth } from 'features/auth/hooks';
import { RoleType } from 'common/models';

interface Props {
  isFetching: boolean;
  fetchingError: unknown | undefined;
  isApproved: boolean | undefined;
  summaryId: number;
}

const ApprovalRowWrapper = styled.div`
  color: ${tableBodyGrey};
  margin: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ApprovalButton = styled(LoginButton)`
  max-width: inherit;
  font-size: inherit;
`;

const MessageContainer = styled.div`
  @media (max-width: ${mobile}) {
    text-align: center;
    width: 40%;
  }
`;

export const ApprovalRow: FC<Props> = ({
  isFetching,
  fetchingError,
  isApproved,
  summaryId,
}) => {
  const { userHasPermission } = useRbac();
  const [isModalOpen, setModal] = useState<boolean>(false);
  const [approveSchedule, result] = useApproveScheduleMutation();
  const { user } = useAuth();

  const isAdmin = user?.role.roleName === RoleType.Admin;

  async function tryApproveSchedule() {
    try {
      await approveSchedule({ id: summaryId, isApproved: true }).unwrap();
    } catch (e) {
      const error = e as FetchBaseQueryError;
      if (error?.status === 461) {
        notifier.showErrorMessage(
          'Please ensure all scheduled picks have been assigned a coordinator.',
        );
      } else if (error?.status === 462) {
        notifier.showErrorMessage(
          'Please ensure all scheduled picks have a crew assigned.',
        );
      } else if (error?.status === 471 || error?.status === 472) {
        notifier.showErrorMessage(
          <>
            <p>Unable to send schedule to Spokane. Technical details:</p>
            <p>{(error.data as { message: string }).message}</p>
          </>,
        );
      } else {
        handleError(error, 'Error approving pick schedule.');
      }
    } finally {
      setModal(false);
    }
  }

  return (
    <ApprovalRowWrapper>
      <WithLoadingOverlay isLoading={isFetching} size='2rem'>
        <>
          {fetchingError && (
            <p className='error'>Error loading approval status.</p>
          )}
          {!fetchingError && isApproved ? (
            <>
              <MessageContainer>
                <CheckIcon style={{ color: green }} />
                &nbsp; Approved by HM
              </MessageContainer>
              {isAdmin && (
                <ApprovalButton type='button' onClick={() => setModal(true)}>
                  Resend Schedule
                </ApprovalButton>
              )}
            </>
          ) : (
            <>
              <MessageContainer className='error'>
                <ErrorIcon />
                &nbsp; Requires HM approval
              </MessageContainer>
              {userHasPermission('pickSchedule:approve') && (
                <ApprovalButton type='button' onClick={() => setModal(true)}>
                  Approve Schedule
                </ApprovalButton>
              )}
            </>
          )}
        </>
      </WithLoadingOverlay>
      <ApproveScheduleModal
        isOpen={isModalOpen}
        isLoading={result.isLoading}
        closeModal={() => setModal(false)}
        onConfirm={() => tryApproveSchedule()}
      />
    </ApprovalRowWrapper>
  );
};
