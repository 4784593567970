import { darkNavy, lighterNavy, orange, white } from 'common/styles/colors';
import styled from 'styled-components';
import MenuIcon from '@mui/icons-material/Menu';
import Header from '../../../common/components/Header';
import logo from '../../../assets/img/Transp_logo.png';
import { useState } from 'react';
import UserCard from 'common/components/UserCard';
import { useNavLinks } from '../hooks/useNavLinks';
import { useLocation } from 'react-router-dom';
import { Constants } from 'utils/constants';
import MobileNavMenu from './MobileNavMenu';

const topBarHeight = '60px';

const FixedTopBar = styled.div`
  color: ${white};
  background-color: ${darkNavy};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 100; // keeps the mobile top bar above child content when scrolling
`;

const NavContainerMobile = styled.nav`
  background-color: ${lighterNavy};
  color: ${white};
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - ${topBarHeight});
  width: 100vw;
`;

const LogoBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  margin: 10px;
`;

const MenuBar = styled.div`
  padding: 0 19px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const menuIconStyle = {
  cursor: 'pointer',
  height: '100%',
  width: '42px',
};

const ChildWrapper = styled.div`
  flex-grow: 1;
`;

type Props = {
  children?: React.ReactNode;
};

const MobileNav = ({ children }: Props): JSX.Element => {
  const navLinks = useNavLinks();
  const { pathname } = useLocation();
  const link =
    navLinks[navLinks.findIndex(link => pathname.startsWith(link.path))];

  const [expanded, setExpanded] = useState(true);
  const closeMenu = () => setExpanded(false);
  const toggleMenu = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <FixedTopBar>
        <LogoBar
          style={{
            height: topBarHeight,
            boxSizing: 'border-box',
            margin: 0,
          }}
        >
          <img src={logo} alt='logo' width='50px' />
          <Header variant='h4'>{Constants.legalCompanyName}</Header>
        </LogoBar>
        {!expanded && (
          <>
            <MenuBar>
              <MenuIcon style={menuIconStyle} onClick={toggleMenu} />
              {link ? (
                <LogoBar>
                  {link.icon && (
                    <link.icon
                      style={{
                        color: orange,
                        fontSize: '35px',
                        marginRight: '4px',
                      }}
                    />
                  )}
                  <Header variant='h3'>{link.label}</Header>
                </LogoBar>
              ) : null}
              <div /> {/* fills right side slot to center LogoBar */}
            </MenuBar>
          </>
        )}
      </FixedTopBar>
      <ChildWrapper style={{ display: expanded ? 'none' : 'flex' }}>
        {children}
      </ChildWrapper>
      <NavContainerMobile style={{ display: expanded ? 'flex' : 'none' }}>
        <UserCard />
        <MobileNavMenu callback={closeMenu} />
      </NavContainerMobile>
    </>
  );
};

export default MobileNav;
