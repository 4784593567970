import { FC, useEffect, useState } from 'react';
import { Constants } from 'utils/constants';
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import {
  useGetGrowerBlockByIdQuery,
  useGetLatestEstimateByIdQuery,
} from 'common/api/growerBlockApi';
import { WithLoadingOverlay } from 'common/components/LoadingSpinner';
import { BlockEvalForm } from '../components/BlockEvalForm';
import { handleError } from 'common/api/handleError';
import { BlockStatus } from 'common/models';
import * as notificationService from 'common/services/notification';
import { EstimatesFieldsProps } from '../components/fields/EstimatesFields';

function notifyInvalidStatus(status: BlockStatus | undefined): void {
  if (status !== undefined) {
    notificationService.showErrorMessage(
      `Cannot view blocks with a status of ${BlockStatus[status]} `,
    );
  } else {
    notificationService.showErrorMessage(`Error reading block status`);
  }
}
export const BlockEvalView: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { url } = useRouteMatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const [isViewable, setViewable] = useState(false);

  // fetch data for page header and details tab
  const {
    data: block,
    isLoading: isLoadingBlock,
    error: blockError,
  } = useGetGrowerBlockByIdQuery({
    id,
    withEvalAndEst: true,
  });

  const {
    data: harvestEstimate,
    isLoading: isLoadingHarvestEstimate,
    error: harvestError,
  } = useGetLatestEstimateByIdQuery(id);

  const estimate: EstimatesFieldsProps = {
    data: harvestEstimate,
    conversionRate: block?.variety?.binConversion,
    isLoading: isLoadingHarvestEstimate,
    error: harvestError,
    estimates: block?.estimates,
  };
  // Navigate the user away for invalid block statuses
  useEffect(() => {
    if (!isLoadingBlock && !blockError) {
      if (
        block?.blockStatus === BlockStatus.Active ||
        block?.blockStatus === BlockStatus.Harvested ||
        block?.blockStatus === BlockStatus.Pending
      ) {
        // Remember if block was previously viewable
        setViewable(true);
      } else {
        // only show notifications if a block was not previously viewable
        if (!isViewable) {
          notifyInvalidStatus(block?.blockStatus);
        }
        history.replace(Constants.routes.EVAL_EST_MAIN);
      }
    }
  }, [block, blockError, history, isLoadingBlock, isViewable]);

  useEffect(() => {
    const tabPath = pathname.substring(url.length);

    // if user tries to access page without a tab, redirect to details tab
    if (tabPath === '') {
      history.replace(`${url}${Constants.routes.DETAILS}`);
    }
  }, [history, pathname, url]);

  useEffect(() => {
    if (blockError) {
      handleError(blockError, 'Unable to load block.');
      history.replace(Constants.routes.EVAL_EST_MAIN);
    }
  }, [blockError, history]);

  return (
    <WithLoadingOverlay isLoading={isLoadingBlock}>
      {block && <BlockEvalForm formData={{ block, estimate }} />}
    </WithLoadingOverlay>
  );
};
