import { DataTableText } from 'common/components/DataTable';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { Column } from 'react-table';
import { AvailBlockToPick } from '../types';
import styled from 'styled-components';
import { useCheckboxColumn } from 'common/components/DataTable/hooks/useCheckboxColumn';
import { CmsDate } from 'common/models/cmsDate';

const CenteredText = styled.span`
  display: flex;
  justify-content: center;
  width: 100%;
  font-family: KanitLight;
  font-size: 16px;
`;

const CenteredHeaderText = styled(CenteredText)`
  font-family: 'KanitSemiBold';
`;

type BlockInfo = {
  block: AvailBlockToPick;
};

type TableColumns = Omit<
  AvailBlockToPick,
  'evaluatorId' | 'varietyId' | 'latestEval'
> & { latestEval: string | undefined };

export type AvailBlocksTableItem = BlockInfo & TableColumns;

export type UseAvailBlocksTableData = (
  setBlocks: Dispatch<SetStateAction<AvailBlockToPick[]>>,
  blocks?: AvailBlockToPick[],
) => {
  columns: Column<AvailBlocksTableItem>[];
  data: AvailBlocksTableItem[];
};

export const useAvailBlocksTableData: UseAvailBlocksTableData = (
  setBlocks,
  blocks = [],
) => {
  const selectBlockColumn: Column<AvailBlocksTableItem> = useCheckboxColumn(
    'block',
    '5%',
    blocks,
    setBlocks,
  );
  const columns: Column<AvailBlocksTableItem>[] = useMemo(
    () => [
      selectBlockColumn,
      {
        accessor: 'binsRemaining',
        Header: () => <CenteredHeaderText>Bins to go</CenteredHeaderText>,
        width: '10%',
        Cell: ({ value: binsToGo }) => (
          <CenteredText>{binsToGo || '0'}</CenteredText>
        ),
      },
      {
        accessor: 'growerId',
        Header: 'Grower ID',
        width: '20%',
        Cell: ({ value: growerId }) => (
          <DataTableText>{growerId.toString()}</DataTableText>
        ),
      },
      {
        accessor: 'blockId',
        Header: 'Block',
        width: '15%',
        Cell: ({ value: blockId }) => (
          <DataTableText>{blockId.toString()}</DataTableText>
        ),
      },
      {
        accessor: 'lotNumber',
        Header: () => <CenteredHeaderText>Lot</CenteredHeaderText>,
        width: '20%',
        Cell: ({ value: lotNumber }) => (
          <CenteredText>{lotNumber.toString() || '-'}</CenteredText>
        ),
      },
      {
        accessor: 'market',
        Header: 'Market',
        width: '15%',
        Cell: ({ value: market }) => <DataTableText>{market}</DataTableText>,
      },
      {
        accessor: 'latestEval',
        Header: 'Latest Eval.',
        width: '15%',
        Cell: ({ value: latestEval }) => (
          <DataTableText>{latestEval || '-'}</DataTableText>
        ),
      },
      {
        accessor: 'harvestPhase',
        Header: () => <CenteredHeaderText>Harv. Phase</CenteredHeaderText>,
        width: '10%',
        Cell: ({ value: harvestPhase }) => (
          <CenteredText>{harvestPhase ? `${harvestPhase}%` : '0'}</CenteredText>
        ),
      },
    ],
    [selectBlockColumn],
  );

  const data: AvailBlocksTableItem[] = useMemo(
    () =>
      blocks.map(block => {
        const { latestEval, ...restBlock } = block;
        return {
          block,
          ...restBlock,
          latestEval: CmsDate.parse(latestEval)?.toString(),
        };
      }),
    [blocks],
  );

  return { columns, data };
};
