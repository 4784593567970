import { useMemo } from 'react';
import { Column } from 'react-table';
import { StatusIcon } from '../components/StatusIcon';
import { GrowerBlockTableItem } from './useGrowerBlockTableData';
import VarietyCell from 'common/components/DataTable/VarietyCell';
import { DataTableText } from 'common/components/DataTable';
import { ForwardArrow } from '../components/GrowerIcons';

export const useGrowerBlockTableDataMobile =
  (): Column<GrowerBlockTableItem>[] => {
    return useMemo(
      () => [
        {
          accessor: 'blockStatus',
          Cell: ({ value: blockStatus }) => (
            <StatusIcon status={blockStatus} type='block' />
          ),
        },
        {
          accessor: 'grower',
          Header: 'Grower ID',
          Cell: ({ value: growerData }) => (
            <DataTableText>{`${growerData?.growerId}`}</DataTableText>
          ),
        },
        {
          accessor: 'blockInfo',
          Header: 'Block ID',
          Cell: ({ value: blockInfo }) => (
            <DataTableText>{`${blockInfo.blockId}`}</DataTableText>
          ),
        },
        {
          accessor: 'variety',
          Header: 'VAR',
          Cell: ({ value }) => <VarietyCell variety={value} />,
        },
        {
          id: 'Arrow',
          Header: '',
          Cell: () => <ForwardArrow />,
        },
      ],
      [],
    );
  };
