import { NestedTab } from 'common/components/NestedTabs/NestedTabs';
import { GrowerBlock } from 'common/models';
import { SizeEstimate } from 'common/models/growerBlock/sizeEstimate';
import { BlockDetailsFields } from 'features/grower-views/components/BlockDetails/BlockDetailsFields';
import { Constants } from 'utils/constants';
import {
  EstimatesFields,
  EstimatesFieldsProps,
} from './fields/EstimatesFields';
import { EvaluationFields } from './fields/EvaluationFields';
import { MonitoringFields } from './fields/MonitoringFields';
import { SizesForm } from './SizesForm';
import { DetailTabHeader } from 'common/components/DetailControls/DetailTabHeader';

export const blockEvalTabs: (
  block: GrowerBlock,
  estimate: EstimatesFieldsProps,
  sizeEstimates: SizeEstimate[],
) => NestedTab[] = (block, estimate, sizeEstimates) => [
  {
    header: (isSelected: boolean) => (
      <DetailTabHeader isSelected={isSelected} tabName='Details' />
    ),
    view: () => <BlockDetailsFields growerBlock={block} />,
    tabPath: Constants.routes.DETAILS,
  },
  {
    header: (isSelected: boolean) => (
      <DetailTabHeader isSelected={isSelected} tabName='Evaluation' />
    ),
    view: () => <EvaluationFields evaluation={block.evaluation} />,
    tabPath: Constants.routes.EVALUATION,
  },
  {
    header: (isSelected: boolean) => (
      <DetailTabHeader isSelected={isSelected} tabName='Sizes' />
    ),
    view: () => <SizesForm sizeEstimates={sizeEstimates} />,
    tabPath: Constants.routes.SIZES,
  },
  {
    header: (isSelected: boolean) => (
      <DetailTabHeader isSelected={isSelected} tabName='Estimates' />
    ),
    view: () => (
      <EstimatesFields
        data={estimate.data}
        isLoading={estimate.isLoading}
        error={estimate.error}
        estimates={block.estimates}
        conversionRate={block.variety.binConversion}
      />
    ),
    tabPath: Constants.routes.ESTIMATES,
  },
  {
    header: (isSelected: boolean) => (
      <DetailTabHeader isSelected={isSelected} tabName='Orchard Monitoring' />
    ),
    view: () => (
      <MonitoringFields orchardMonitoring={block.orchardMonitoring} />
    ),
    tabPath: Constants.routes.MONITORING,
  },
];
