import { DataTableText } from 'common/components/DataTable/DataTableStyles';
import VarietyCell from 'common/components/DataTable/VarietyCell';
import { Grower } from 'common/models';
import { CmsDate } from 'common/models/cmsDate';
import { GrowerBlock } from 'common/models/growerBlock';
import { useMemo } from 'react';
import { Column } from 'react-table';
import { ForwardArrow } from '../components/GrowerIcons';
import { StatusIcon } from '../components/StatusIcon';

export type GrowerBlockTableItem = Pick<
  GrowerBlock,
  'blockStatus' | 'variety' | 'grower' | 'acres' | 'lastStatusUpdate'
> & {
  blockInfo: {
    id: number;
    blockId: string;
    grower: Partial<Grower>;
  };
  parentGrower: string | null;
};

export type UseGrowerBlockTableData = (agents?: GrowerBlock[]) => {
  columns: Column<GrowerBlockTableItem>[];
  data: GrowerBlockTableItem[];
};

export const useGrowerBlockTableData: UseGrowerBlockTableData = (
  growerBlocks = [],
) => {
  const columns: Column<GrowerBlockTableItem>[] = useMemo(
    () => [
      {
        accessor: 'blockStatus',
        width: '3%',
        Cell: ({ value: blockStatus }) => (
          <StatusIcon status={blockStatus} type='block' />
        ),
      },
      {
        accessor: 'lastStatusUpdate',
        Header: 'Last Updated',
        width: '8%',
        Cell: ({ value }) => (
          <span>{CmsDate.parse(value)?.toDateString() ?? ''}</span>
        ),
      },
      {
        accessor: 'grower',
        Header: 'Grower ID',
        Cell: ({ value: growerData }) => (
          <DataTableText>{`${growerData?.growerId}`}</DataTableText>
        ),
      },
      {
        accessor: 'blockInfo',
        Header: 'Block ID',
        Cell: ({ value: blockInfo }) => (
          <DataTableText>{`${blockInfo.blockId}`}</DataTableText>
        ),
      },
      {
        accessor: 'variety',
        Header: 'Variety',
        Cell: ({ value }) => <VarietyCell variety={value} />,
      },
      {
        accessor: 'acres',
        Header: 'Acres',
        Cell: ({ value: acres }) => (
          <DataTableText>{acres || '-'}</DataTableText>
        ),
      },
      {
        accessor: 'parentGrower',
        Header: 'Parent Grower',
        Cell: ({ value: parentGrower }) => (
          <DataTableText>{parentGrower || '-'}</DataTableText>
        ),
      },
      {
        id: 'Arrow',
        Header: '',
        width: '5%',
        Cell: () => <ForwardArrow />,
      },
    ],
    [],
  );

  const data: GrowerBlockTableItem[] = useMemo(
    () =>
      growerBlocks.map(block => {
        const {
          id,
          blockStatus,
          grower,
          blockId,
          variety,
          acres,
          lastStatusUpdate,
        } = block;
        const blockInfo = { id, blockId, grower };
        const parentGrower = grower?.parentGrowerName;

        return {
          blockInfo,
          blockStatus,
          grower,
          variety,
          acres,
          parentGrower,
          lastStatusUpdate,
        };
      }),
    [growerBlocks],
  );

  return { columns, data };
};
