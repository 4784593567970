/* eslint-disable camelcase */
import {
  useUpdateBlockEvaluationMutation,
  useUpdateGrowerBlockMutation,
  useUpdateLatestEstimateMutation,
  useUpdateOrchardMonitoringMutation,
  useUpdateSizesMutation,
} from 'common/api/growerBlockApi';
import { useCallback } from 'react';
import * as notifier from 'common/services/notification';
import { handleError } from 'common/api/handleError';
import { Constants } from 'utils/constants';
import { useParams } from 'react-router-dom';
import { gibbTrueOption } from 'utils/formValues/evaluation';
import {
  UpdateEvalReq,
  UpdateLatestEstimateReq,
  UpdateSizesReq,
  UpdateOrchardMonitoring,
} from 'common/api/growerBlockApiTypes';
import { BlockEvalFormData, UpdateLatestEstimateFormValues } from '../types';
import { useAuth } from 'features/auth/hooks';
import { yesOption } from 'utils/formValues/commonOptions';
import { CmsDate } from 'common/models/cmsDate';

type BlockEvalActions = {
  updateBlock: (formData: BlockEvalFormData) => Promise<void>;
  updateEvaluation: (formData: BlockEvalFormData) => Promise<void>;
  updateHarvestEstimate: (formData: BlockEvalFormData) => Promise<void>;
  updateSizeEstimates: (formData: BlockEvalFormData) => Promise<void>;
  updateOrchardMonitoring: (formData: BlockEvalFormData) => Promise<void>;
};

const useBlockEvalActions = (): BlockEvalActions => {
  const { id } = useParams<{ id: string }>();
  const { user } = useAuth();
  const [updateGrowerBlock] = useUpdateGrowerBlockMutation();
  const [updateBlockEvaluation] = useUpdateBlockEvaluationMutation();
  const [updateLatestEstimate] = useUpdateLatestEstimateMutation();
  const [updateSizes] = useUpdateSizesMutation();
  const [updateMonitoring] = useUpdateOrchardMonitoringMutation();

  const updateBlock = useCallback(
    async (formData: BlockEvalFormData) => {
      const blockToUpdate: BlockEvalFormData & { id: number } = {
        id: parseInt(id, 10),
        ...formData,
      };

      try {
        await updateGrowerBlock(blockToUpdate).unwrap();
        notifier.showSuccessMessage('Grower block updated.');
      } catch (error) {
        handleError(error, Constants.errorMessages.SAVE_FAILED);
        throw error;
      }
    },
    [id, updateGrowerBlock],
  );

  const updateEvaluation = useCallback(
    async (formData: BlockEvalFormData) => {
      const {
        evaluationDate,
        gibb,
        gibbRate,
        market,
        color,
        drop,
        priority,
        condition,
      } = formData;
      const evalToUpdate = {
        blockId: parseInt(id, 10),
        evaluationDate: CmsDate.fromDateOnly(evaluationDate)?.toISO() ?? null,
        // convert gibb dropdown option from type 'Option' to type 'boolean'
        gibb: gibb.label === gibbTrueOption.label,
        gibbRate,
        market,
        color,
        drop,
        priority,
        condition,
      } as UpdateEvalReq;

      try {
        await updateBlockEvaluation(evalToUpdate).unwrap();
        notifier.showSuccessMessage('Evaluation updated.');
      } catch (error) {
        handleError(error, Constants.errorMessages.SAVE_FAILED);
        throw error;
      }
    },
    [id, updateBlockEvaluation],
  );

  const updateHarvestEstimate = useCallback(
    async (formData: BlockEvalFormData) => {
      const { estimatedBins: estimate }: UpdateLatestEstimateFormValues =
        formData;
      const estimateData: UpdateLatestEstimateReq = {
        blockId: parseInt(id, 10),
        userId: user?.id ?? null,
        // We are enforcing the type here because validation must have passed by now.
        estimatedBins: estimate as number,
      };

      try {
        await updateLatestEstimate(estimateData).unwrap();
        notifier.showSuccessMessage('Latest Estimate updated.');
      } catch (error) {
        handleError(error, Constants.errorMessages.SAVE_FAILED);
        throw error;
      }
    },
    [id, updateLatestEstimate, user?.id],
  );

  const updateSizeEstimates = useCallback(
    async (formData: BlockEvalFormData) => {
      const updateReq: UpdateSizesReq = {
        blockId: parseInt(id, 10),
        sizeEstimates: formData.sizeEstimates,
      };
      try {
        await updateSizes(updateReq).unwrap();
        notifier.showSuccessMessage('Size Estimates updated.');
      } catch (error) {
        handleError(error, Constants.errorMessages.SAVE_FAILED);
        throw error;
      }
    },
    [id, updateSizes],
  );

  const updateOrchardMonitoring = useCallback(
    async (formData: BlockEvalFormData) => {
      const {
        monitorDate,
        harvestDate,
        skirtPruned,
        copperSpray_1,
        copperSpray_2,
        frbSpray_1,
        frbSpray_2,
        brownRot,
        usdaList,
        ckApproved,
        sampleDate,
        brix,
        acid,
        ratio,
      } = formData;
      const updateReq: UpdateOrchardMonitoring = {
        blockId: parseInt(id, 10),
        monitorDate: CmsDate.fromDateOnly(monitorDate)?.toISO(),
        harvestDate: CmsDate.fromDateOnly(harvestDate)?.toISO(),
        skirtPruned: skirtPruned.label === yesOption.label,
        copperSpray_1: CmsDate.fromDateOnly(copperSpray_1)?.toISO(),
        copperSpray_2: CmsDate.fromDateOnly(copperSpray_2)?.toISO(),
        frbSpray_1: CmsDate.fromDateOnly(frbSpray_1)?.toISO(),
        frbSpray_2: CmsDate.fromDateOnly(frbSpray_2)?.toISO(),
        brownRot: brownRot.label === yesOption.label,
        usdaList: usdaList.label === yesOption.label,
        ckApproved: ckApproved.label === yesOption.label,
        sampleDate: CmsDate.fromDateOnly(sampleDate)?.toISO(),
        brix,
        acid,
        ratio,
      } as UpdateOrchardMonitoring;
      try {
        await updateMonitoring(updateReq).unwrap();
        notifier.showSuccessMessage('Orchard Monitoring updated.');
      } catch (error) {
        handleError(error, Constants.errorMessages.SAVE_FAILED);
        throw error;
      }
    },
    [id, updateMonitoring],
  );

  return {
    updateBlock,
    updateEvaluation,
    updateHarvestEstimate,
    updateSizeEstimates,
    updateOrchardMonitoring,
  };
};

export default useBlockEvalActions;
