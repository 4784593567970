import { Role, RoleHierarchy, RoleType, User } from 'common/models';

export type Permission =
  | 'role:read'
  | 'user:create'
  | 'user:read'
  | 'user:update'
  | 'user:delete'
  | 'user:resend-activation-email'
  | 'user:send-reset-password-email'
  | 'growers:read'
  | 'grower:edit-all'
  | 'grower:edit-cert-info'
  | 'grower:edit-manager-info'
  | 'block:read'
  | 'block:edit'
  | 'block:admin-edit'
  | 'blockStatus:change'
  | 'myBlocks/EvalsTable:read'
  | 'allEvals/EvalsTable:read'
  | 'blankEvals/EvalsTable:read'
  | 'otherEvals/EvalsTable:read'
  | 'evalEstTabs:read'
  | 'evalEstTabs:create/edit'
  | 'evalEst:read'
  | 'packPlan:read'
  | 'packPlan:create-row'
  | 'packPlan:edit-bin-request'
  | 'settings:read'
  | 'pickSchedule:read'
  | 'pickSchedule:approve'
  | 'pick:read'
  | 'pick:assign-crew'
  | 'pick:input-data'
  | 'pick:remove'
  | 'pick:schedule'
  | 'payroll:read'
  | 'export:growers'
  | 'export:eval-and-est';

export type Predicate = (user: User, data?: unknown) => boolean;

export type Rule = boolean | Predicate;

export type PermissionMap = {
  [P in Permission]: Rule;
};

export type RbacRules = {
  [R in RoleType]?: PermissionMap;
};

const rules: RbacRules = {
  'System Administrator': {
    'role:read': true,
    'user:create': true,
    'user:read': true,
    'user:update': true,
    'user:delete': (self: User, other: unknown) =>
      (other as User).id !== self.id,
    'user:resend-activation-email': true,
    'user:send-reset-password-email': (self: User, other: unknown) =>
      (other as User).id !== self.id,
    'growers:read': true,
    'grower:edit-all': true,
    'grower:edit-cert-info': true,
    'grower:edit-manager-info': true,
    'block:read': true,
    'block:edit': true,
    'block:admin-edit': true,
    'blockStatus:change': true,
    'myBlocks/EvalsTable:read': false,
    'allEvals/EvalsTable:read': true,
    'blankEvals/EvalsTable:read': true,
    'otherEvals/EvalsTable:read': false,
    'evalEstTabs:read': true,
    'evalEstTabs:create/edit': true,
    'evalEst:read': true,
    'packPlan:read': true,
    'packPlan:create-row': true,
    'packPlan:edit-bin-request': true,
    'settings:read': true,
    'pickSchedule:read': true,
    'pickSchedule:approve': true,
    'pick:read': true,
    'pick:assign-crew': true,
    'pick:input-data': true,
    'pick:remove': true,
    'pick:schedule': true,
    'payroll:read': true,
    'export:growers': true,
    'export:eval-and-est': true,
  },
  Analyst: {
    'role:read': (user: User, role: unknown) =>
      RoleHierarchy[user.role.roleName] >=
      RoleHierarchy[(role as Role).roleName],
    'user:create': false,
    'user:read': false,
    'user:update': false,
    'user:delete': false,
    'user:resend-activation-email': false,
    'user:send-reset-password-email': (self: User, other: unknown) =>
      (other as User).id !== self.id,
    'growers:read': true,
    'grower:edit-all': false,
    'grower:edit-cert-info': true,
    'grower:edit-manager-info': false,
    'block:read': true,
    'block:edit': false,
    'block:admin-edit': false,
    'blockStatus:change': false,
    'myBlocks/EvalsTable:read': false,
    'allEvals/EvalsTable:read': true,
    'blankEvals/EvalsTable:read': false,
    'otherEvals/EvalsTable:read': false,
    'evalEstTabs:read': true,
    'evalEstTabs:create/edit': false,
    'evalEst:read': true,
    'packPlan:read': true,
    'packPlan:create-row': true,
    'packPlan:edit-bin-request': true,
    'pickSchedule:read': true,
    'pickSchedule:approve': false,
    'pick:read': false,
    'pick:assign-crew': false,
    'pick:input-data': false,
    'pick:remove': false,
    'pick:schedule': false,
    'payroll:read': false,
    'settings:read': false,
    'export:growers': false,
    'export:eval-and-est': false,
  },
  'Field Representative': {
    'role:read': (user: User, role: unknown) =>
      RoleHierarchy[user.role.roleName] >=
      RoleHierarchy[(role as Role).roleName],
    'user:create': false,
    'user:read': false,
    'user:update': false,
    'user:delete': false,
    'user:resend-activation-email': true,
    'user:send-reset-password-email': (self: User, other: unknown) =>
      (other as User).id !== self.id,
    'growers:read': true,
    'grower:edit-all': false,
    'grower:edit-cert-info': false,
    'grower:edit-manager-info': true,
    'block:read': true,
    'block:edit': true,
    'block:admin-edit': false,
    'blockStatus:change': false,
    'myBlocks/EvalsTable:read': true,
    'allEvals/EvalsTable:read': false,
    'blankEvals/EvalsTable:read': false,
    'otherEvals/EvalsTable:read': true,
    'evalEstTabs:read': true,
    'evalEstTabs:create/edit': true,
    'evalEst:read': true,
    'packPlan:read': true,
    'packPlan:create-row': false,
    'packPlan:edit-bin-request': false,
    'pickSchedule:read': true,
    'pickSchedule:approve': false,
    'pick:read': true,
    'pick:assign-crew': false,
    'pick:input-data': false,
    'pick:remove': true,
    'pick:schedule': true,
    'payroll:read': false,
    'settings:read': false,
    'export:growers': false,
    'export:eval-and-est': true,
  },
  'Harvest Coordinator': {
    'role:read': (user: User, role: unknown) =>
      RoleHierarchy[user.role.roleName] >=
      RoleHierarchy[(role as Role).roleName],
    'user:create': false,
    'user:read': false,
    'user:update': false,
    'user:delete': false,
    'user:resend-activation-email': false,
    'user:send-reset-password-email': (self: User, other: unknown) =>
      (other as User).id !== self.id,
    'growers:read': false,
    'grower:edit-all': false,
    'grower:edit-cert-info': false,
    'grower:edit-manager-info': false,
    'block:read': false,
    'block:edit': false,
    'block:admin-edit': false,
    'blockStatus:change': false,
    'myBlocks/EvalsTable:read': false,
    'allEvals/EvalsTable:read': false,
    'blankEvals/EvalsTable:read': false,
    'otherEvals/EvalsTable:read': false,
    'evalEstTabs:read': false,
    'evalEstTabs:create/edit': false,
    'evalEst:read': false,
    'packPlan:read': false,
    'packPlan:create-row': false,
    'packPlan:edit-bin-request': false,
    'pickSchedule:read': true,
    'pickSchedule:approve': false,
    'pick:read': true,
    'pick:assign-crew': false,
    'pick:input-data': true,
    'pick:remove': false,
    'pick:schedule': false,
    'payroll:read': false,
    'settings:read': false,
    'export:growers': false,
    'export:eval-and-est': false,
  },
  'Harvest Manager': {
    'role:read': (user: User, role: unknown) =>
      RoleHierarchy[user.role.roleName] >=
      RoleHierarchy[(role as Role).roleName],
    'user:create': false,
    'user:read': false,
    'user:update': false,
    'user:delete': false,
    'user:resend-activation-email': false,
    'user:send-reset-password-email': (self: User, other: unknown) =>
      (other as User).id !== self.id,
    'growers:read': false,
    'grower:edit-all': false,
    'grower:edit-cert-info': false,
    'grower:edit-manager-info': false,
    'block:read': false,
    'block:edit': false,
    'block:admin-edit': false,
    'blockStatus:change': false,
    'myBlocks/EvalsTable:read': false,
    'allEvals/EvalsTable:read': false,
    'blankEvals/EvalsTable:read': false,
    'otherEvals/EvalsTable:read': false,
    'evalEstTabs:read': false,
    'evalEstTabs:create/edit': false,
    'evalEst:read': false,
    'packPlan:read': true,
    'packPlan:create-row': false,
    'packPlan:edit-bin-request': false,
    'pickSchedule:read': true,
    'pickSchedule:approve': true,
    'pick:read': true,
    'pick:assign-crew': true,
    'pick:input-data': true,
    'pick:remove': false,
    'pick:schedule': false,
    'payroll:read': false,
    'settings:read': false,
    'export:growers': false,
    'export:eval-and-est': false,
  },
};

export const getRbacRules = (): RbacRules => rules;
