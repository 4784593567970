import { skipToken } from '@reduxjs/toolkit/query';
import { handleError } from 'common/api/handleError';
import { useGetPoolsQuery } from 'common/api/harvestDataApi';
import { useGetPackHousesQuery } from 'common/api/packHouse.api';
import { WithLoadingOverlay } from 'common/components/LoadingSpinner';
import { GrowerBlock, Variety } from 'common/models';
import { Size } from 'common/models/growerBlock/size';
import { PackHouse } from 'common/models/packHouse';
import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Constants } from 'utils/constants';
import { Title } from '../../pack-plan-views/pages/PackPlanView/styles';
import { SchedulePickWizard } from '../components/SchedulePickWizard/SchedulePickWizard';
import { AvailBlockToPick } from '../types';

export type SchedulePickWizardValues = {
  adHoc?: GrowerBlock;
  planned?: {
    blocks: AvailBlockToPick[];
    packHouse: PackHouse;
    variety: Variety;
    size: Size;
    requestId: number;
  };
};

export const SchedulePickWizardView: FC = () => {
  const history = useHistory();
  const { state } = useLocation<SchedulePickWizardValues>();
  const {
    data: packHouses,
    isLoading: isLoadingPH,
    error: fetchHousesError,
  } = useGetPackHousesQuery();
  const {
    data: pools,
    isLoading: isLoadingPools,
    error: fetchPoolsError,
  } = useGetPoolsQuery(
    state?.adHoc?.variety?.id || state?.planned?.variety?.id || skipToken,
  );

  const [initialBlocksLength] = useState(
    state?.adHoc ? 1 : state?.planned?.blocks.length || 0,
  );
  const [blocks, setBlocks] = useState(
    state?.adHoc ? [state.adHoc] : state?.planned?.blocks || [],
  );
  const [selectionExists] = useState(
    initialBlocksLength && !!(state?.planned || state?.adHoc),
  );

  const handleNextBlock = () => setBlocks(blocks => blocks && blocks.slice(1));

  useEffect(() => {
    if (fetchHousesError)
      handleError(fetchHousesError, 'Unable to load pack houses.');
    if (fetchPoolsError) handleError(fetchPoolsError, 'Unable to load pools.');

    if (!selectionExists) {
      // Revert to the pick schedule when called w/o proper values in state.
      history.replace(`${Constants.routes.PICK_SCHEDULE}`);
    } else if (!blocks.length) {
      // Exit scheduling loop when there are no more blocks to schedule.
      history.push(
        `${
          state.adHoc
            ? Constants.routes.PICK_SCHEDULE
            : Constants.routes.PACK_PLAN
        }`,
      );
    }
  }, [
    blocks,
    fetchHousesError,
    fetchPoolsError,
    history,
    selectionExists,
    state,
  ]);

  return (
    <WithLoadingOverlay isLoading={isLoadingPH || isLoadingPools}>
      <Title>Schedule New Pick</Title>
      {initialBlocksLength > 1 && (
        <span>{`Scheduling ${
          initialBlocksLength - blocks.length + 1
        } of ${initialBlocksLength} blocks`}</span>
      )}
      {selectionExists && packHouses && pools && blocks[0] && (
        <SchedulePickWizard
          packHouses={packHouses}
          pools={pools}
          {...(state.adHoc
            ? { adHocBlock: blocks[0] as GrowerBlock }
            : { availBlock: blocks[0] as AvailBlockToPick })}
          packHouse={state.planned?.packHouse}
          variety={state?.planned?.variety}
          size={state?.planned?.size}
          requestId={state?.planned?.requestId}
          handleNextBlock={handleNextBlock}
        />
      )}
    </WithLoadingOverlay>
  );
};
