import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from 'common/api/customBaseQuery';
import { FilterCategoryOptions } from 'common/hooks';
import {
  GrowerBlock,
  PaginatedResult,
  PaginationQueryParams,
} from 'common/models';
import { HarvestEstimate } from 'common/models/growerBlock/harvestEstimate';
import { AvailBlockToPick } from 'features/pick-schedule-views/types';
import {
  EvalAndEst,
  UpdateEvalReq,
  UpdateLatestEstimateReq,
  UpdateBlockStatusRequest,
  UpdateSizesReq,
  UpdateOrchardMonitoring,
} from './growerBlockApiTypes';
import { withFilters } from './utils/apiFunctions';
import { BlockEvalFormData } from 'features/evaluation-views/types';
import { DateWithoutTime } from 'common/models/dateType';
import { Market } from 'common/models/market';

type BlockFilterQueryParams = {
  filters: FilterCategoryOptions[];
};

type SizeEstimateData = {
  blockId: number;
  percentage: number;
  size: string;
};

export type AllEvalEstData = {
  id: number;
  growerId: string;
  blockId: string;
  variety: string;
  evalDate: DateWithoutTime;
  market: Market;
  gibb: boolean;
  initialEst: number;
  currentEst: number;
  binsPicked: number;
  sizeEstimates: SizeEstimateData[];
};

const blocksBaseUrl = '/grower-blocks';

const needsReviewTag = 'NeedsReview';
const needsEvaluationTag = 'NeedsEvaluation';
const otherEvaluationTag = 'OtherEvaluation';
const blankEvaluationTag = 'BlankEvaluation';
const toScheduleTag = 'ToSchedule';
const blockTag = 'Block';
const evaluationTag = 'Evaluation';
const estimateTag = 'Estimate';

export const growerBlockApi = createApi({
  reducerPath: 'growerBlockApi',
  baseQuery: customBaseQuery,
  refetchOnReconnect: true,
  tagTypes: [
    needsReviewTag,
    needsEvaluationTag,
    otherEvaluationTag,
    blankEvaluationTag,
    toScheduleTag,
    blockTag,
    evaluationTag,
    estimateTag,
  ],

  endpoints: builder => ({
    getBlocksByNeedReview: builder.query<
      PaginatedResult<GrowerBlock>,
      PaginationQueryParams & BlockFilterQueryParams
    >({
      query: prm => withFilters(prm, `${blocksBaseUrl}/need-review`),
      providesTags: [needsReviewTag],
    }),

    getBlocksByNeedEvaluation: builder.query<
      PaginatedResult<GrowerBlock>,
      PaginationQueryParams & BlockFilterQueryParams
    >({
      query: prm => withFilters(prm, `${blocksBaseUrl}/evaluations`),
      providesTags: (_, err) => (err ? [] : [needsEvaluationTag]),
    }),

    getBlocksByOtherEvaluations: builder.query<
      PaginatedResult<GrowerBlock>,
      PaginationQueryParams & BlockFilterQueryParams
    >({
      query: prm => withFilters(prm, `${blocksBaseUrl}/other-evaluations`),
      providesTags: (_, err) => (err ? [] : [otherEvaluationTag]),
    }),

    getBlocksByBlankEvaluations: builder.query<
      PaginatedResult<GrowerBlock>,
      PaginationQueryParams & BlockFilterQueryParams
    >({
      query: prm => withFilters(prm, `${blocksBaseUrl}/blank-evaluations`),
      providesTags: (_, err) => (err ? [] : [blankEvaluationTag]),
    }),

    getBlocksToSchedule: builder.query<
      PaginatedResult<AvailBlockToPick>,
      {
        varietyId: number;
        pickDay: DateWithoutTime;
        packHouseId: number;
      } & PaginationQueryParams &
        BlockFilterQueryParams
    >({
      query: ({ varietyId, pickDay, packHouseId, ...psfParams }) =>
        withFilters(
          psfParams,
          `${blocksBaseUrl}/schedule/${varietyId}/${pickDay}/${packHouseId}`,
        ),
      providesTags: (_, err) => (err ? [] : [toScheduleTag]),
    }),

    getGrowerBlockById: builder.query<
      GrowerBlock,
      number | string | EvalAndEst
    >({
      query: req =>
        typeof req !== 'object'
          ? `${blocksBaseUrl}/${req}`
          : `${blocksBaseUrl}/${req.id}?withEvalAndEst=${req.withEvalAndEst}`,
      providesTags: (_, err, args) => {
        if (err) return [];
        if (typeof args === 'string' || typeof args === 'number') {
          return [{ type: blockTag, id: args }];
        }
        return [{ type: blockTag, id: args.id }];
      },
    }),

    updateBlockStatus: builder.mutation<GrowerBlock, UpdateBlockStatusRequest>({
      query: ({ id, ...payload }) => ({
        url: `${blocksBaseUrl}/change-status/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (_, err, args) =>
        err ? [] : [{ type: blockTag, id: args.id }],
    }),

    updateGrowerBlock: builder.mutation<
      GrowerBlock,
      (BlockEvalFormData & { id: number }) | GrowerBlock
    >({
      query: ({ id, ...payload }) => ({
        url: `${blocksBaseUrl}/${id}`,
        method: 'PUT',
        body: payload,
      }),
      // Refetch all blocks as well as the block with the updated details.
      invalidatesTags: (_, err, args) =>
        err
          ? []
          : [
              needsReviewTag,
              needsEvaluationTag,
              otherEvaluationTag,
              blankEvaluationTag,
              toScheduleTag,
              { type: blockTag, id: args.id },
            ],
    }),

    updateBlockEvaluation: builder.mutation<GrowerBlock, UpdateEvalReq>({
      query: ({ blockId, ...payload }) => ({
        url: `${blocksBaseUrl}/evaluations/${blockId}`,
        method: 'PUT',
        body: payload,
      }),
      // Refetch all blocks as well as the block with the updated evaluation.
      invalidatesTags: (_, err, args) =>
        err
          ? []
          : [
              needsReviewTag,
              needsEvaluationTag,
              otherEvaluationTag,
              blankEvaluationTag,
              toScheduleTag,
              { type: blockTag, id: args.blockId },
            ],
    }),

    updateSizes: builder.mutation<GrowerBlock, UpdateSizesReq>({
      query: ({ blockId, sizeEstimates }) => ({
        url: `${blocksBaseUrl}/size-estimates/${blockId}`,
        method: 'PUT',
        body: { sizeEstimates },
      }),
      // Refetch the block with the updated sizes.
      invalidatesTags: (_, err, args) =>
        err ? [] : [{ type: blockTag, id: args.blockId }],
    }),

    updateOrchardMonitoring: builder.mutation<
      GrowerBlock,
      UpdateOrchardMonitoring
    >({
      query: ({ blockId, ...payload }) => ({
        url: `${blocksBaseUrl}/orchard-monitoring/${blockId}`,
        method: 'PUT',
        body: payload,
      }),
      // Refetch the block with the updated orchard monitoring.
      invalidatesTags: (_, err, args) =>
        err ? [] : [{ type: blockTag, id: args.blockId }],
    }),

    getLatestEstimateById: builder.query<HarvestEstimate, number | string>({
      query: id => `${blocksBaseUrl}/latest-estimate/${id}`,
      providesTags: (_, err, id) => (err ? [] : [{ type: estimateTag, id }]),
    }),

    updateLatestEstimate: builder.mutation<
      HarvestEstimate,
      UpdateLatestEstimateReq
    >({
      query: ({ blockId, ...payload }) => ({
        url: `${blocksBaseUrl}/latest-estimate/${blockId}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (_, err, args) =>
        err ? [] : [{ type: estimateTag, id: args.blockId }],
    }),

    getEvalEstCsvData: builder.query<AllEvalEstData[], void>({
      query: () => ({
        url: `${blocksBaseUrl}/evaluation-data`,
        method: 'GET',
      }),
      // Don't provide tags as we always want the latest from the server.
    }),
  }),
});

export const {
  useGetBlocksByNeedReviewQuery,
  useGetGrowerBlockByIdQuery,
  useGetLatestEstimateByIdQuery,
  useUpdateGrowerBlockMutation,
  useUpdateBlockEvaluationMutation,
  useUpdateLatestEstimateMutation,
  useUpdateSizesMutation,
  useUpdateBlockStatusMutation,
  useUpdateOrchardMonitoringMutation,
  useGetBlocksByNeedEvaluationQuery,
  useGetBlocksByOtherEvaluationsQuery,
  useGetBlocksByBlankEvaluationsQuery,
  useGetBlocksToScheduleQuery,
  useGetEvalEstCsvDataQuery,
} = growerBlockApi;
