import { PaginatedResult } from 'common/models';
import { GrowerBlock } from 'common/models/growerBlock';
import { useGetBlocksByNeedReviewQuery } from 'common/api/growerBlockApi';
import { FC, useEffect, useMemo } from 'react';
import {
  DataTable,
  DataTableMobile,
  MobileStyling,
  DataTableProps,
} from 'common/components/DataTable';
import { usePSFQuery } from 'common/hooks';
import {
  GrowerBlockTableItem,
  useGrowerBlockTableData,
} from '../hooks/useGrowerBlockTableData';
import { useGrowerBlockTableDataMobile } from '../hooks/useGrowerBlockTableDataMobile';
import useWindowSize from 'common/hooks/useWindowSize';
import { mobile } from 'common/styles/breakpoints';
import { handleError } from 'common/api/handleError';
import { Constants } from 'utils/constants';
import { PSFKeys, usePSFState } from 'utils/hooks/usePSFState';
import {
  blockIdFilter,
  blockStatusFilter,
  showAllBlocksFilter,
} from 'utils/tableFilters/blockFilters';

export const BlockChangesTable: FC = () => {
  const { query, setQuery } = usePSFState(PSFKeys.BlockChanges);
  const {
    data,
    isLoading,
    isFetching,
    pagination,
    applyFilters,
    filters,
    error: getBlocksError,
  } = usePSFQuery<PaginatedResult<GrowerBlock>>(
    useGetBlocksByNeedReviewQuery,
    {},
    { ...query },
    setQuery,
  );
  const growerBlocks = useMemo(() => data?.results ?? [], [data]);
  const { columns, data: tableData } = useGrowerBlockTableData(growerBlocks);
  const columnsMobile = useGrowerBlockTableDataMobile();

  const { width } = useWindowSize();
  const isMobile = width < parseInt(mobile, 10);

  const sharedTableProps: DataTableProps<GrowerBlockTableItem> = {
    title: 'Block Changes',
    unitToPaginate: 'Blocks',
    isLoading,
    isFetching,
    columns: isMobile ? columnsMobile : columns,
    data: tableData,
    pagination,
    onRowClick: block =>
      `${Constants.routes.GROWER_BLOCK_DETAILS}${block.blockInfo.id}`,
    filtering: {
      filterOptions: [showAllBlocksFilter, blockIdFilter, blockStatusFilter],
      applyFilters,
      defaultFilters: filters,
    },
    loadError: !!getBlocksError,
  };

  useEffect(() => {
    if (getBlocksError) {
      handleError(getBlocksError, 'Unable to load block changes');
    }
  }, [getBlocksError]);

  return (
    <>
      {isMobile ? (
        <MobileStyling>
          <DataTableMobile<GrowerBlockTableItem> {...sharedTableProps} />
        </MobileStyling>
      ) : (
        <DataTable<GrowerBlockTableItem> {...sharedTableProps} />
      )}
    </>
  );
};
