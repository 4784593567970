import { CmsDate } from 'common/models/cmsDate';
import { HarvestEstimate } from 'common/models/growerBlock/harvestEstimate';
import { UpdateLatestEstimateFormValues } from 'features/evaluation-views/types';

/** Returns default values for the estimate form */
export const createLatestEstimateFormValues = (
  estimate: HarvestEstimate | undefined,
): UpdateLatestEstimateFormValues => ({
  estimateDate: CmsDate.parse(estimate?.estimateDate)?.toDateString() ?? null,
  estimatedBins: estimate?.estimatedBins ?? null,
  binsPicked: estimate?.binsPicked ?? null,
  binsToHarvest: estimate?.binsToHarvest ?? null,
  harvestDateForEstimate:
    CmsDate.parse(estimate?.harvestDate)?.toDateString() ?? null,
});
