import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from './customBaseQuery';
import { PickScheduleData } from 'common/models/pickSchedule';
import {
  EditScheduledPickPayload,
  ScheduledPickPayload,
} from 'features/pick-schedule-views/hooks/usePickScheduleActions';
import { ScheduledPick } from 'common/models';

export type DaySummaryDto = {
  id: number;
  scheduledTotal: number;
  pickedTotal: number;
  isApproved: boolean;
};

const pickScheduleBaseUrl = '/harvest-data';
const scheduledPickPath = 'scheduled-pick';

export const scheduledPickTag = 'ScheduledPick';

export const pickScheduleApi = createApi({
  reducerPath: 'pickScheduleApi',
  baseQuery: customBaseQuery,
  refetchOnReconnect: true,
  tagTypes: [scheduledPickTag],

  endpoints: builder => ({
    getPickSchedule: builder.query<
      PickScheduleData,
      { date: string; packHouseId: number }
    >({
      query: ({ date, packHouseId }) => ({
        url: `${pickScheduleBaseUrl}/${scheduledPickPath}/${date}/${packHouseId}`,
        method: 'GET',
      }),
      // Don't provide tags here, as we always want to fetch pick schedules.
    }),

    getPickScheduleSummary: builder.query<DaySummaryDto, string>({
      query: date => ({
        url: `${pickScheduleBaseUrl}/day-summary/${date}`,
        method: 'GET',
      }),
      // Don't provide tags here, as we always want to have the latest summary.
    }),

    approveSchedule: builder.mutation<
      void,
      { id: number; isApproved: boolean }
    >({
      query: body => ({
        url: `${pickScheduleBaseUrl}/schedule-approval`,
        method: 'PUT',
        body,
      }),
      // Don't provide tags here, as we always get the latest summary.
    }),

    schedulePick: builder.mutation<void, ScheduledPickPayload>({
      query: scheduledPick => ({
        url: `${pickScheduleBaseUrl}/${scheduledPickPath}`,
        method: 'POST',
        body: scheduledPick,
      }),
      // Don't provide/invalidate tags here, as pick schedules are always fetched.
    }),

    getScheduledPickById: builder.query<ScheduledPick, { id: number }>({
      query: ({ id }) => ({
        url: `${pickScheduleBaseUrl}/${scheduledPickPath}/${id}`,
        method: 'GET',
      }),
      providesTags: (_, err, arg) =>
        err ? [] : [{ type: scheduledPickTag, id: arg.id }],
    }),

    editScheduledPick: builder.mutation<void, EditScheduledPickPayload>({
      query: ({ id, schedId, ...payload }) => ({
        url: `${pickScheduleBaseUrl}/${scheduledPickPath}/${schedId}/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (_, err, arg) =>
        err ? [] : [{ type: scheduledPickTag, id: arg.id }],
    }),

    deletePickSchedule: builder.mutation<void, number>({
      query: pickId => ({
        url: `${pickScheduleBaseUrl}/${scheduledPickPath}/${pickId}`,
        method: 'DELETE',
      }),
      // Don't invalidate tags here, as pick schedules are always fetched.
    }),
  }),
});

export const {
  useGetPickScheduleQuery,
  useGetPickScheduleSummaryQuery,
  useGetScheduledPickByIdQuery,
  useDeletePickScheduleMutation,
  useSchedulePickMutation,
  useEditScheduledPickMutation,
  useApproveScheduleMutation,
} = pickScheduleApi;
