import { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  ConfirmChangeEmailRequest,
  useConfirmChangeEmailMutation,
} from 'common/api/userApi';
import * as notificationService from 'common/services/notification';
import { handleError } from 'common/api/handleError';
import {
  ConfirmChangeEmailForm,
  FormData,
} from '../components/ConfirmChangeEmailForm';
import { StyledContainer } from 'common/components/HolyGrailLayout/components/NoTokenLayout';
import Header from 'common/components/Header';

export const ConfirmChangeEmailPage: FC = () => {
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const [confirmChangeEmail] = useConfirmChangeEmailMutation();

  const onSubmit = async (formData: FormData) => {
    try {
      const requestPayload = { ...formData, token };
      await confirmChangeEmail(
        // Force type here because validation must have passed by now.
        requestPayload as ConfirmChangeEmailRequest,
      ).unwrap();
      notificationService.showSuccessMessage('Email change successful.');
      history.push('/auth/login');
    } catch (error) {
      handleError(error, 'Invalid or expired verification code');
    }
  };

  return (
    <StyledContainer data-testid='wrapper'>
      <Header variant='h4'>Verify Email Change</Header>
      <ConfirmChangeEmailForm onSubmit={onSubmit} />
    </StyledContainer>
  );
};
