import styled from 'styled-components';
import { mobile } from './breakpoints';

export const PageWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.pages.backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MobileScrollWrapper = styled.div`
  @media (max-width: ${mobile}) {
    flex-grow: 1;
    // sets minimum height for the NavLinkDiv since min-height only affects flex-basis values
    height: 100px;
    overflow: auto;
  }
`;

export const boxShadow = '0px 3px 12px rgba(0, 0, 0, 0.2)';
