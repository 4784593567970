import { useAppDispatch, useAppSelector } from 'app/redux';
import {
  ContractorInvoicePick,
  HarvestLineType,
  HaulingLineType,
  MiscLineType,
} from 'common/models/harvestData/payrollData';
import currency from 'currency.js';
import {
  PayrollTableRow,
  RowGroupKeys,
  RowMetadata,
  emptyPayrollRow,
} from 'features/harvest-payroll/utils/payrollDataTypes';
import { toEnumLabel } from 'utils/enumFunctions';
import { ValidatedPickRow } from '../components/PayrollTable/AddCustomLineForm/AddPickLineForm';
import {
  EditablePickRows,
  PayrollPageState,
  PayrollRowMap,
  PicksTableRows,
  payrollSlice,
  payrollSliceName,
} from '../PayrollSlice';
import {
  createRowKey,
  createRowSubtotal,
  createTableDate,
  isRemovableRow,
  isSameGroup,
} from '../utils/payrollPageUtils';
import {
  formatHarvestRows,
  formatHaulRows,
  formatMiscRows,
} from '../utils/payrollTableUtils';
import { AmbiguousCommissionTarget } from './ambiguousCommissionTarget.error';

/** Hook for picks table actions. */
export const usePickTable = () => {
  const dispatch = useAppDispatch();
  const { picks } = useAppSelector<PayrollPageState>(
    state => state[payrollSliceName],
  );
  const setHarvestRows = (pickId: number, rows: PayrollRowMap) => {
    dispatch(
      payrollSlice.actions.setPickRows({
        pickId,
        key: 'harvestRows',
        rows,
        updateTotal: false,
      }),
    );
  };
  const setHaulingRows = (pickId: number, rows: PayrollRowMap) => {
    dispatch(
      payrollSlice.actions.setPickRows({
        pickId,
        key: 'haulRows',
        rows,
        updateTotal: false,
      }),
    );
  };
  const setPickRowsWithTotal = (
    pickId: number,
    rows: PayrollRowMap | PayrollTableRow[],
    key: EditablePickRows,
  ) => {
    dispatch(
      payrollSlice.actions.setPickRows({
        pickId,
        key,
        rows,
        updateTotal: true,
      }),
    );
  };

  /**
   * Updates a commission row based on the values of the other rows in its group.
   *
   * @param rows - The array containing the rows to calculate a commission for.
   * @param target - Indicates which group of rows to calculate the commission
   *  for. This is only necessary when {@link rows} contains multiple groups and
   *  can be omitted otherwise.
   */
  const updateCommission = (
    rows: PayrollTableRow[],
    target?: RowGroupKeys,
  ): PayrollTableRow[] => {
    let categoryTotal = currency(0);
    let commIdx = -1;
    const newRows = [...rows];
    const defaultTarget = rows[0].metadata;

    newRows.forEach((row, index) => {
      const { descType } = row.metadata;
      const matchesTarget = isSameGroup(row.metadata, target ?? defaultTarget);

      if (matchesTarget) {
        switch (descType) {
          case HarvestLineType[HarvestLineType.Forklift]:
          case HaulingLineType[HaulingLineType.Hauling]:
          case HaulingLineType[HaulingLineType.Fuel_Surcharge]:
          case HaulingLineType[HaulingLineType.Minimum_Load]:
          case HaulingLineType[HaulingLineType.Minimum_Load_Surcharge]:
          case MiscLineType[MiscLineType.MISC]:
            // Filter out subtotals.
            break;

          case HarvestLineType[HarvestLineType.Commission]:
          case HaulingLineType[HaulingLineType.Commission]:
            commIdx = index;
            break;

          default:
            // Use all other subtotals for commission.
            categoryTotal = currency(categoryTotal).add(row.subtotal);
        }
      } else if (!target) {
        throw new AmbiguousCommissionTarget(defaultTarget, row.metadata);
      }
    });

    if (commIdx > 0) {
      const { rate } = newRows[commIdx];

      const commRow = {
        ...newRows[commIdx],
        rate:
          typeof rate === 'string' && rate.includes('%') ? rate : `${rate}%`,
        subtotal: createRowSubtotal(
          currency(rate).value,
          categoryTotal.value,
          true,
        ),
      };

      newRows[commIdx] = commRow;
    }

    return newRows;
  };

  /**
   * Method to create the picks table rows from contractor invoice pick data. Each
   * pick has its harvesting and/or hauling rows's commission calculated and then set
   * in slice state along with misc. rows.
   * */
  const createPickDefaults = (dftPicks: ContractorInvoicePick[]): void => {
    dftPicks.forEach(pick => {
      const { pickId, pickDay, growerId, poolId, blockId } = pick;
      const pickData = {
        pickId,
        blockId,
        pickDate: createTableDate(pickDay),
        pickInfo: `${growerId} ${poolId}`,
      };

      if (pick.harvestItems.length > 0) {
        const rowMap = formatHarvestRows(pickData, pick.harvestItems, grp =>
          updateCommission(grp),
        );

        setHarvestRows(pickId, rowMap);
      }

      if (pick.haulingItems.length > 0) {
        const rowMap = formatHaulRows(
          pickData,
          pick.haulingItems,
          pick.harvestItems.length > 0,
          grp => updateCommission(grp),
        );

        setHaulingRows(pickId, rowMap);
      }

      dispatch(
        payrollSlice.actions.setPickRows({
          pickId,
          key: 'miscRows',
          rows: pick.miscItems.length > 0 ? formatMiscRows(pick.miscItems) : [],
          updateTotal: false,
        }),
      );
    });
  };

  /**
   * Finds the rows that belong to a specified group in a collection of pick rows,
   * along with information about the group and its entire category that is useful
   * when making changes to the group.
   *
   * @param targetKeys - The keys that identify the group to find.
   * @param picks - The collection of pick rows to search within, assumed to
   * come from the Redux store.
   */
  const findGroupForEdits = (
    targetKeys: RowGroupKeys,
    picks: PicksTableRows[],
  ): {
    /** The category to which the found group belongs. */
    category: {
      key: EditablePickRows;
      /**
       * The rows belonging to the category, contained in a new map object that
       * can be modified and sent back to the store.
       */
      rows: PayrollRowMap;
    };
    /** The group of rows that was found. */
    group: {
      /**
       * The rows belonging to the group, contained in a new array so it can be
       * modified and sent back to the store.
       */
      rows: PayrollTableRow[];
      /** The ID that can be used to access the group within its category. */
      id: number;
      /** The position of the commission row within the group. */
      commissionIndex: number;
    };
  } => {
    const { pickId } = targetKeys;
    const pick = picks.find(rec => rec.pickId === pickId);

    if (!pick) {
      throw new Error(
        `Pick with ID '${pickId}' not found. ` +
          `The searched list was ${picks.length} item(s) long.`,
      );
    }

    // Find the category within the pick.
    const categoryKey: EditablePickRows =
      targetKeys.descCategory === HarvestLineType[HarvestLineType.Harvesting]
        ? 'harvestRows'
        : 'haulRows';
    const categoryRows: PayrollRowMap = { ...pick[categoryKey] };

    // Find the group within the category.
    const groupId =
      targetKeys.contractorRecordId ?? (targetKeys.haulerRecordId as number);
    const groupRows = [...categoryRows[groupId]];

    // Find other useful information about the group.
    const commissionIndex = groupRows.findLastIndex(
      row =>
        row.metadata.descType === HarvestLineType[HarvestLineType.Commission] ||
        row.metadata.descType === HaulingLineType[HaulingLineType.Commission],
    );

    return {
      category: {
        key: categoryKey,
        rows: categoryRows,
      },
      group: {
        rows: groupRows,
        id: groupId,
        commissionIndex,
      },
    };
  };

  /** Method to add or edit a pick row. */
  const addPickRow = (
    pickId: number,
    discriminator: number,
    { quantity, category, type, rate }: ValidatedPickRow,
    defaultCtrRecId: number | null,
    defaultHaulRecIds: {
      contractorRecordId: number | null;
      haulerRecordId: number | null;
    },
    rates?: { haulRate: string; surchargeRate: string },
  ): void => {
    let contractorRecordId: number | null;
    let haulerRecordId: number | null = null;
    let newInvItemType: 'harv' | 'haul';
    const isHarvesting =
      category.value === HarvestLineType[HarvestLineType.Harvesting];

    if (isHarvesting) {
      contractorRecordId = defaultCtrRecId as number;
      newInvItemType = 'harv';
    } else {
      contractorRecordId = defaultHaulRecIds.contractorRecordId;
      haulerRecordId = defaultHaulRecIds.haulerRecordId;
      newInvItemType = 'haul';
    }

    const row: PayrollTableRow = {
      ...emptyPayrollRow,
      description: toEnumLabel(type.value),
      metadata: {
        pickId,
        contractorRecordId,
        haulerRecordId,
        itemId: null,
        discriminator: null,
        descCategory: category.value,
        descType: type.value,
        invItemType: newInvItemType,
      },
    };

    const {
      category: categoryGroup,
      group: { rows: targetRows, commissionIndex, ...targetGroup },
    } = findGroupForEdits(row.metadata, picks);

    if (isRemovableRow(row.metadata.descType)) {
      // Add new invoice item
      row.metadata = { ...row.metadata, discriminator };
      row.quantity = quantity;

      if (
        row.metadata.descType === HaulingLineType[HaulingLineType.Minimum_Load]
      ) {
        const haulRate = rates?.haulRate || '0';
        const surchargeRate = rates?.surchargeRate || '0';

        const minLoadRow = {
          ...row,
          metadata: {
            ...row.metadata,
            descType: HaulingLineType[HaulingLineType.Minimum_Load],
          },
          rate: haulRate,
          subtotal: createRowSubtotal(
            currency(haulRate).value,
            currency(quantity).value,
          ),
        };

        const minLoadSurchargeRow = {
          ...row,
          metadata: {
            ...row.metadata,
            descType: HaulingLineType[HaulingLineType.Minimum_Load_Surcharge],
          },
          description: toEnumLabel(
            HaulingLineType[HaulingLineType.Minimum_Load_Surcharge],
          ),
          rate: surchargeRate,
          subtotal: createRowSubtotal(
            currency(surchargeRate).value,
            currency(quantity).value,
          ),
        };

        targetRows.splice(commissionIndex, 0, minLoadRow, minLoadSurchargeRow);
      } else {
        row.rate = currency(rate).format();
        row.subtotal = createRowSubtotal(
          currency(rate).value,
          currency(quantity).value,
        );

        targetRows.splice(commissionIndex, 0, row);
      }
    } else {
      // Update invoice item
      const isCommission =
        type.value === HarvestLineType[HarvestLineType.Commission];

      if (isCommission) {
        row.metadata.itemId = targetRows[commissionIndex].metadata.itemId;
        row.rate = rate;
        targetRows[commissionIndex] = row;
      } else {
        const rowToEditIdx = targetRows.findIndex(
          item => item.description === row.description,
        );

        if (rowToEditIdx > -1) {
          const rowToEdit = targetRows[rowToEditIdx];

          targetRows[rowToEditIdx] = {
            ...rowToEdit,
            rate: currency(rate).format(),
            subtotal: createRowSubtotal(
              currency(rate).value,
              currency(rowToEdit.quantity).value,
            ),
          };

          // Temp code to handle pick records, from prior versions, having no surcharges.
          // This can be removed when modifying old records is no longer a concern.
        } else if (
          type.value === HaulingLineType[HaulingLineType.Fuel_Surcharge]
        ) {
          const surchargeRow = {
            ...row,
            metadata: { ...row.metadata, discriminator },
            quantity,
            rate: currency(rate).format(),
            subtotal: createRowSubtotal(
              currency(rate).value,
              currency(quantity).value,
            ),
          };

          targetRows.splice(commissionIndex, 0, surchargeRow);
        }
      }
    }

    categoryGroup.rows[targetGroup.id] = updateCommission(targetRows);

    setPickRowsWithTotal(pickId, categoryGroup.rows, categoryGroup.key);
    dispatch(payrollSlice.actions.incrementNewRowCount());
  };

  /** Method to add a miscellaneous row. */
  const addMiscRow = (
    pickId: number,
    discriminator: number,
    { quantity, note, rate }: ValidatedPickRow,
  ) => {
    const pickRecord = picks.find(record => record.pickId === pickId);
    const row: PayrollTableRow = {
      ...emptyPayrollRow,
      metadata: {
        ...emptyPayrollRow.metadata,
        pickId,
        itemId: null,
        discriminator,
        descCategory: MiscLineType[MiscLineType.MISC],
        descType: MiscLineType[MiscLineType.MISC],
      },
      quantity,
      description: note,
      rate: currency(rate).format(),
      subtotal: createRowSubtotal(
        currency(rate).value,
        currency(quantity).value,
      ),
    };

    if (pickRecord) {
      dispatch(
        payrollSlice.actions.setPickRows({
          pickId,
          key: 'miscRows',
          rows: [...pickRecord.miscRows, row],
          updateTotal: true,
        }),
      );
      dispatch(payrollSlice.actions.incrementNewRowCount());
    }
  };

  /** Method to remove a pick row. */
  const removePickRow = (metadata: RowMetadata): void => {
    const { pickId } = metadata;
    const rowId = createRowKey(metadata);

    const {
      category,
      group: { rows: targetRows, id: groupId },
    } = findGroupForEdits(metadata, picks);

    const rowIndex = targetRows.findIndex(
      row => createRowKey(row.metadata) === rowId,
    );

    category.rows[groupId] = updateCommission(
      targetRows.toSpliced(rowIndex, 1),
    );

    setPickRowsWithTotal(pickId, category.rows, category.key);
  };

  /** Method to remove a miscellaneous row. */
  const removeMiscRow = (metadata: RowMetadata): void => {
    const { pickId } = metadata;
    const rowId = createRowKey(metadata);

    const pickIdx = picks.findIndex(pick => pick.pickId === pickId);
    let newRows = [...picks[pickIdx].miscRows];

    const rowIndex = newRows.findIndex(
      row => createRowKey(row.metadata) === rowId,
    );

    newRows = newRows.toSpliced(rowIndex, 1);

    dispatch(
      payrollSlice.actions.setPickRows({
        pickId,
        key: 'miscRows',
        rows: newRows,
        updateTotal: true,
      }),
    );

    if (metadata.itemId) {
      dispatch(payrollSlice.actions.setMiscItemsToDelete(metadata.itemId));
    }
  };

  return {
    createPickDefaults,
    addPickRow,
    addMiscRow,
    removePickRow,
    removeMiscRow,
  };
};
