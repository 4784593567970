import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  PayrollTableRow,
  emptyPayrollRow,
  totalIdKey,
} from 'features/harvest-payroll/utils/payrollDataTypes';
import currency from 'currency.js';
import { Contractor } from 'common/models';

export type EditablePickRows = keyof Pick<
  PicksTableRows,
  'harvestRows' | 'haulRows' | 'miscRows'
>;

type PickRowsToUpdate<
  K extends EditablePickRows,
  T extends PicksTableRows[K],
> = {
  pickId: number;
  key: K;
  /**
   * The updated rows to set in state for the pick's `key` property. **Make sure
   * to pass the right type**, see remarks.
   *
   * @remarks
   *
   * Since the type of this property is tied to the value of the `key` property,
   * this property must be able to accept two types:
   *
   * - If the `key` property is `'harvestRows'` or `'haulRows'`, then this
   *   property must be set to a value of type {@link PayrollRowMap}.
   * - If the `key` property is `'miscRows'`, then this property must be set to
   *   an _array_ of type {@link PayrollTableRow}.
   *
   * There seems to be no way to enforce this in TypeScript (even though the type
   * constraints would seem to imply so), so the onus is on the caller to pass
   * the right type here, for the given `key`.
   */
  rows: T;
  /** When `true`, the picks table total recalculates. */
  updateTotal: boolean;
};

type TransferRowsToUpdate = {
  rows: TransfersTableRows[];
  /** When `true`, the transfers table total recalculates. */
  updateTotal: boolean;
};

type InvoiceTotalToUpdate = {
  /** When `true`, the invoice total recalculates. */
  updateInvTotal: boolean;
};

/**
 * Represents an object whose properties are contractor (or hauler) record IDs
 * and its values are the pick rows corresponding to each record ID.
 */
export type PayrollRowMap = { [id: number]: PayrollTableRow[] };

export type PicksTableRows = {
  pickId: number;
  harvestRows: PayrollRowMap;
  haulRows: PayrollRowMap;
  miscRows: PayrollTableRow[];
  pickTotal: PayrollTableRow;
};

export type TransfersTableRows = {
  id: number;
  rows: PayrollTableRow[];
  transferTotal: PayrollTableRow;
};

export type PayrollPageState = {
  selectedContractor: Contractor | null;
  picks: PicksTableRows[];
  transfers: TransfersTableRows[];
  /** Array to track transfer invoice item IDs to be deleted server side. */
  transfersToDelete: number[];
  /** Array to track miscellaneous invoice item IDs to be deleted server side. */
  miscItemsToDelete: number[];
  picksTotal: PayrollTableRow;
  transfersTotal: PayrollTableRow;
  invoiceTotal: PayrollTableRow;
  /** Boolean to track payroll form editability. */
  isEditable: boolean;
  /** Boolean to track if the payroll form needs to be saved. */
  isDirty: boolean;
  /** Incremented number used for new row JSX keys. */
  newRowCount: number;
};

export const payrollSliceName = 'payrollSlice';

/** Name of a slice reducer to set the picks total. */
export const setPickTotalStr = 'setPickTotal';

/** Name of a slice reducer to set the transfers total. */
export const setTransferTotalStr = 'setTransferTotal';

const initialState: PayrollPageState = {
  selectedContractor: null,
  picks: [],
  transfers: [],
  transfersToDelete: [],
  miscItemsToDelete: [],
  picksTotal: {
    ...emptyPayrollRow,
    rate: 'Picks Total:',
    subtotal: currency(0).format(),
  },
  transfersTotal: {
    ...emptyPayrollRow,
    rate: 'Transfers Total:',
    subtotal: currency(0).format(),
  },
  invoiceTotal: {
    ...emptyPayrollRow,
    rate: 'Invoice Total:',
    subtotal: currency(0).format(),
  },
  isEditable: false,
  isDirty: false,
  newRowCount: 0,
};

export const payrollSlice = createSlice({
  name: payrollSliceName,
  initialState,
  reducers: {
    setContractor: (
      state: PayrollPageState,
      action: PayloadAction<Contractor>,
    ) => {
      state.selectedContractor = action.payload;
    },
    resetTableDataAndForm: (state: PayrollPageState) => {
      state.picks = initialState.picks;
      state.transfers = initialState.transfers;
      state.picksTotal = initialState.transfersTotal;
      state.transfersTotal = initialState.transfersTotal;
      state.transfersToDelete = initialState.transfersToDelete;
      state.miscItemsToDelete = initialState.miscItemsToDelete;
      state.isEditable = initialState.isEditable;
      state.isDirty = initialState.isDirty;
      state.newRowCount = initialState.newRowCount;
    },
    setPickRows: <K extends EditablePickRows, T extends PicksTableRows[K]>(
      state: PayrollPageState,
      action: PayloadAction<PickRowsToUpdate<K, T>>,
    ) => {
      const {
        payload: { pickId, key, rows, updateTotal },
      } = action;
      const { picks } = state;
      const recordIdx = picks.findIndex(rcd => rcd.pickId === pickId);

      if (recordIdx > -1) {
        picks[recordIdx][key] = rows;
      } else {
        picks.push({
          pickId,
          harvestRows: [],
          haulRows: [],
          miscRows: [],
          ...{ [key]: rows },
          pickTotal: emptyPayrollRow,
        });
      }

      if (updateTotal) {
        payrollSlice.caseReducers.setPickTotal(state, {
          payload: { updateInvTotal: updateTotal },
          type: setPickTotalStr,
        });
      }
    },
    setMiscItemsToDelete: (
      state: PayrollPageState,
      action: PayloadAction<number>,
    ) => {
      state.miscItemsToDelete = [...state.miscItemsToDelete, action.payload];
    },
    [setPickTotalStr]: (
      state: PayrollPageState,
      action: PayloadAction<InvoiceTotalToUpdate>,
    ) => {
      const { picks } = state;
      let allPicksTotal = currency(0);

      picks.forEach(({ harvestRows, haulRows, miscRows }, index) => {
        let pickTotal = currency(0);
        const allHarvestRows = Object.values(harvestRows).flat();
        const allHaulRows = Object.values(haulRows).flat();

        [...allHarvestRows, ...allHaulRows, ...miscRows].forEach(row => {
          pickTotal = pickTotal.add(row.subtotal);
        });

        picks[index].pickTotal = {
          ...emptyPayrollRow,
          metadata: {
            ...emptyPayrollRow.metadata,
            pickId: picks[index].pickId,
            descCategory: 'pick',
            descType: totalIdKey,
          },
          rate: 'Total:',
          subtotal: pickTotal.format(),
        };

        allPicksTotal = allPicksTotal.add(pickTotal);
      });

      state.picksTotal = {
        ...initialState.picksTotal,
        subtotal: allPicksTotal.format(),
      };

      if (action.payload.updateInvTotal) {
        payrollSlice.caseReducers.setInvoiceTotal(state);
      }
    },
    setTransferRows: (
      state: PayrollPageState,
      action: PayloadAction<TransferRowsToUpdate>,
    ) => {
      const {
        payload: { rows, updateTotal },
      } = action;

      state.transfers = rows;

      if (updateTotal) {
        payrollSlice.caseReducers.setTransferTotal(state, {
          payload: { updateInvTotal: updateTotal },
          type: setTransferTotalStr,
        });
      }
    },
    setTransfersToDelete: (
      state: PayrollPageState,
      action: PayloadAction<number>,
    ) => {
      state.transfersToDelete = [...state.transfersToDelete, action.payload];
    },
    [setTransferTotalStr]: (
      state: PayrollPageState,
      action: PayloadAction<InvoiceTotalToUpdate>,
    ) => {
      const { transfers } = state;
      let transfersTotal = currency(0);

      transfers.forEach(({ rows }, index) => {
        let transferTotal = currency(0);

        rows.forEach(row => {
          transferTotal = transferTotal.add(row.subtotal);
        });

        transfers[index] = {
          ...transfers[index],
          transferTotal: {
            ...transfers[index].transferTotal,
            subtotal: transferTotal.format(),
          },
        };

        transfersTotal = transfersTotal.add(transferTotal);
      });

      state.transfersTotal = {
        ...initialState.transfersTotal,
        subtotal: transfersTotal.format(),
      };

      if (action.payload.updateInvTotal) {
        payrollSlice.caseReducers.setInvoiceTotal(state);
      }
    },
    setInvoiceTotal: (state: PayrollPageState) => {
      const { picksTotal, transfersTotal } = state;

      state.invoiceTotal = {
        ...state.invoiceTotal,
        subtotal: currency(picksTotal.subtotal)
          .add(transfersTotal.subtotal)
          .format(),
      };
    },
    setIsEditable: (
      state: PayrollPageState,
      action: PayloadAction<boolean>,
    ) => {
      state.isEditable = action.payload;
    },
    setIsDirty: (state: PayrollPageState, action: PayloadAction<boolean>) => {
      state.isDirty = action.payload;
    },
    incrementNewRowCount: (
      state: PayrollPageState,
      action: PayloadAction<number | undefined>,
    ) => {
      if (action.payload) {
        state.newRowCount += action.payload;
      } else {
        state.newRowCount += 1;
      }
    },
  },
});
