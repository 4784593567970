import React, { FC } from 'react';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom';
import { NavLinkConfig } from '../hooks/useNavLinks';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import styled from 'styled-components';
import { darkNavy, orange, white } from 'common/styles/colors';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;

  &.active {
    background-color: ${darkNavy};
  }

  &.child {
    background-color: ${white};
    color: ${darkNavy};
  }

  a:visited {
    color: ${white};
  }
`;

const ExpandButtonStyling = { marginRight: '1rem', cursor: 'pointer' };

type Props = {
  link: NavLinkConfig;
  active?: boolean;
  child?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  hasSublinks?: boolean;
  expanded?: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  toggle?: Function;
  id?: number;
};

export const MobileNavLink: FC<Props> = ({
  link,
  active,
  child,
  onClick: callback,
  hasSublinks,
  expanded,
  toggle,
  id,
}) => {
  const Icon = link.icon;

  return (
    <Container className={`${active ? 'active' : ''} ${child ? 'child' : ''}`}>
      <Nav.Link
        as={NavLink}
        to={link.path}
        style={{ color: child ? darkNavy : white }}
        onClick={callback}
      >
        <div className='d-flex gap-1'>
          {Icon && <Icon style={{ color: active ? orange : '' }} />}
          {child && <>&nbsp;&nbsp;</>}
          <span style={{ whiteSpace: 'nowrap' }} data-testid='link-title'>
            {link.label}
          </span>
        </div>
      </Nav.Link>
      {hasSublinks &&
        (expanded ? (
          <ExpandLess
            style={ExpandButtonStyling}
            onClick={() => {
              if (toggle) toggle(id);
            }}
          />
        ) : (
          <ExpandMore
            style={ExpandButtonStyling}
            onClick={() => {
              if (toggle) toggle(id);
            }}
          />
        ))}
    </Container>
  );
};
