import * as yup from 'yup';
import { dateSchemaRequired, percentageSchema } from './common';

export const monitoringSchema = yup.object({
  monitorDate: dateSchemaRequired,
  harvestDate: dateSchemaRequired,
  copperSpray_1: dateSchemaRequired,
  copperSpray_2: dateSchemaRequired,
  frbSpray_1: dateSchemaRequired,
  frbSpray_2: dateSchemaRequired,
  sampleDate: dateSchemaRequired,
  brix: percentageSchema.required(),
  acid: percentageSchema.required(),
  ratio: percentageSchema.required(),
});
