import UserCard from 'common/components/UserCard';
import Header from 'common/components/Header';
import useWindowSize from 'common/hooks/useWindowSize';
import {
  darkNavy,
  lighterNavy,
  secondaryWhite,
  white,
} from 'common/styles/colors';
import NavMenu from 'features/navbar/components/NavMenu';
import styled from 'styled-components';
import { mobile } from 'common/styles/breakpoints';
import { Link } from 'react-router-dom';
import MobileNav from 'features/navbar/components/MobileNav';
import { Constants } from 'utils/constants';
import { useAuth } from 'features/auth/hooks';
import { determinePathOnLogin } from 'utils/helperFunction';

interface props {
  children?: React.ReactNode;
}

const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  background-color: ${secondaryWhite};

  @media (max-width: ${mobile}) {
    flex-direction: column;
  }
`;

const ContentContainer = styled.div`
  width: calc(100vw - max(280px, 15vw));
  margin-left: max(280px, 15vw);

  @media (max-width: ${mobile}) {
    margin-left: 0;
    width: 100vw;
    display: flex;
    flex-direction: column;
  }
`;

const StyledNav = styled.nav`
  position: fixed;
  background-color: ${lighterNavy};
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${white};
  width: max(280px, 15vw);
  height: 100vh;
`;

const SectionContainer = styled.section`
  padding: 40px;
  width: 100%;

  @media (max-width: ${mobile}) {
    padding: 0;
  }
`;

const TopBar = styled.div`
  background-color: ${darkNavy};
  padding: 1rem 2rem;
  display: flex;
  justify-content: flex-end;
  color: ${white};

  h4,
  p {
    margin: 0;
    padding: 0;
  }
  a {
    text-decoration: none;
    color: ${white};
  }
`;

const TokenLayout: React.FC<props> = ({ children }): JSX.Element => {
  const { width } = useWindowSize();
  const isMobile = width < parseInt(mobile, 10);
  const { user } = useAuth();

  return (
    <Wrapper>
      {isMobile ? (
        <MobileNav>
          <ContentContainer>{children}</ContentContainer>
        </MobileNav>
      ) : (
        <>
          <StyledNav>
            <UserCard />
            <NavMenu />
          </StyledNav>
          <ContentContainer>
            <TopBar>
              <Link to={determinePathOnLogin(user?.role.roleName)}>
                <Header variant='h4'>{Constants.legalCompanyName}</Header>
                <p>{Constants.appName}</p>
              </Link>
            </TopBar>
            <SectionContainer>{children}</SectionContainer>
          </ContentContainer>
        </>
      )}
    </Wrapper>
  );
};

export default TokenLayout;
