import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from './customBaseQuery';
import { Pool } from 'common/models/harvestData/pool';
import { Contractor } from 'common/models/harvestData/contractor';
import { Hauler } from 'common/models/harvestData/hauler';
import {
  AssignTeamPayload,
  PickRecordPayload,
} from 'features/pick-schedule-views/hooks/usePickScheduleActions';
import { PayrollData } from 'common/models/harvestData/payrollData';
import { SavePayrollPayload } from 'features/harvest-payroll/hooks/useSavePayroll';

export type HaulRateDto = {
  id: number;
  labelId: number;
  range: string;
  rate: number;
  surcharge: number;
};

export type TransferRateDto = {
  id: number;
  surchargeId: number;
  route: string;
  rate: number;
  surcharge: number;
  chargeTarps: boolean;
};

export type PickRateDto = {
  id: number;
  varietyId: number;
  rate: number;
};

const harvestDataBaseUrl = '/harvest-data';

const poolsTag = 'Pools';
const pickRatesTag = 'PickRates';
const haulRatesTag = 'HaulRates';
const transferRatesTag = 'TransferRates';
const harvestCrewsTag = 'HarvestCrews';
const ctrPayrollTag = 'CtrPayroll';

export const harvestDataApi = createApi({
  reducerPath: 'harvestDataApi',
  baseQuery: customBaseQuery,
  refetchOnReconnect: true,
  tagTypes: [
    poolsTag,
    pickRatesTag,
    haulRatesTag,
    transferRatesTag,
    harvestCrewsTag,
    ctrPayrollTag,
  ],

  endpoints: builder => ({
    getPools: builder.query<Pool[], number>({
      query: id => ({
        url: `${harvestDataBaseUrl}/pools/${id}`,
        method: 'GET',
      }),
      providesTags: (_, err) => (err ? [] : [poolsTag]),
    }),

    getPickRates: builder.query<PickRateDto[], void>({
      query: () => ({
        url: `${harvestDataBaseUrl}/pick-rates`,
        method: 'GET',
      }),
      providesTags: (_, err) => (err ? [] : [pickRatesTag]),
    }),

    getHaulRates: builder.query<HaulRateDto[], void>({
      query: () => ({
        url: `${harvestDataBaseUrl}/haul-rates`,
        method: 'GET',
      }),
      providesTags: (_, err) => (err ? [] : [haulRatesTag]),
    }),

    getTransferRates: builder.query<TransferRateDto[], void>({
      query: () => ({
        url: `${harvestDataBaseUrl}/transfer-rates`,
        method: 'GET',
      }),
      providesTags: (_, err) => (err ? [] : [transferRatesTag]),
    }),

    getHarvestCrews: builder.query<
      { fieldLaborContractors: Contractor[]; haulers: Hauler[] },
      void
    >({
      query: () => ({
        url: `${harvestDataBaseUrl}/harvest-crew`,
        method: 'GET',
      }),
      providesTags: (_, err) => (err ? [] : [harvestCrewsTag]),
    }),

    assignTeam: builder.mutation<void, AssignTeamPayload>({
      query: team => ({
        url: `${harvestDataBaseUrl}/assign-staff-and-crew`,
        method: 'PUT',
        body: team,
      }),
      // Because the scheduled pick tag is part of a different api,
      // we invalidate it manually in the component where this mutation is used.
    }),

    savePickRecord: builder.mutation<void, PickRecordPayload>({
      query: data => ({
        url: `${harvestDataBaseUrl}/pick-record`,
        method: 'PUT',
        body: data,
      }),
      // Because the scheduled pick tag is part of a different api,
      // we invalidate it manually in the component where this mutation is used.
    }),

    getPayrollDataByContractor: builder.query<
      PayrollData,
      { contractorId: number; startDate: string }
    >({
      query: ({ contractorId, startDate }) => ({
        url: `${harvestDataBaseUrl}/contractor-invoice/${contractorId}/${startDate}`,
        method: 'GET',
      }),
      providesTags: (_, err) => (err ? [] : [ctrPayrollTag]),
    }),

    savePayroll: builder.mutation<void, SavePayrollPayload>({
      query: rows => ({
        url: `${harvestDataBaseUrl}/contractor-invoice`,
        method: 'PUT',
        body: rows,
      }),
      invalidatesTags: (_, err) => (err ? [] : [ctrPayrollTag]),
    }),
  }),
});

export const {
  useGetPoolsQuery,
  useGetPickRatesQuery,
  useGetHaulRatesQuery,
  useGetTransferRatesQuery,
  useGetHarvestCrewsQuery,
  useAssignTeamMutation,
  useSavePickRecordMutation,
  useGetPayrollDataByContractorQuery,
  useSavePayrollMutation,
} = harvestDataApi;
