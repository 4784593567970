import { Credentials, useLogin } from 'features/auth/hooks';
import { FC } from 'react';
import { FormData, LogInForm } from '../components/LoginForm';
import { ForgotPasswordLink } from '../components/ForgotPasswordLink';
import HeaderBack from '../components/HeaderBack';
import { StyledContainer } from 'common/components/HolyGrailLayout/components/NoTokenLayout';
import { handleError } from 'common/api/handleError';
import { isApiError } from 'common/api/isApiError';
import { StatusCodes } from 'http-status-codes';

export const LogInPage: FC = () => {
  const { login } = useLogin();

  const onSubmit = async (credentials: FormData) => {
    try {
      // Force type here because validation must have passed by now.
      await login(credentials as Credentials);
    } catch (error) {
      const message =
        isApiError(error) && error.status === StatusCodes.UNAUTHORIZED
          ? 'Unable to log in with the provided credentials, please try again.'
          : 'There was an issue logging in, please try again later.';

      // Component name passed because Sentry scrubs errors relating to auth
      handleError(error, message, 'LogInPage');
    }
  };

  return (
    <StyledContainer>
      <HeaderBack title='Sign In' />
      <LogInForm onSubmit={onSubmit} />
      <ForgotPasswordLink />
    </StyledContainer>
  );
};
