import { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ActivateAccountPage } from './pages/ActivateAccountPage';
import { ConfirmChangeEmailPage } from './pages/ConfirmChangeEmailPage';
import { ForgotPasswordPage } from './pages/ForgotPasswordPage';
import { LogInPage } from './pages/LoginPage';
import { ResetPasswordPage } from './pages/ResetPasswordPage';
import NoTokenLayout from 'common/components/HolyGrailLayout/components/NoTokenLayout';
import { NotFoundView } from 'common/components/NotFound';

export const Routes: FC = () => {
  const { path } = useRouteMatch();

  return (
    <NoTokenLayout>
      <Switch>
        <Route exact path='/auth/login' component={LogInPage} />
        <Route
          exact
          path='/auth/activate-account/:token'
          component={ActivateAccountPage}
        />
        <Route
          exact
          path='/auth/confirm-change-email/:token'
          component={ConfirmChangeEmailPage}
        />
        <Route
          exact
          path='/auth/forgot-password'
          component={ForgotPasswordPage}
        />
        <Route
          exact
          path='/auth/reset-password/:firstName/:token'
          component={ResetPasswordPage}
        />
        <Route path={path} component={NotFoundView} />
      </Switch>
    </NoTokenLayout>
  );
};
