import { DataTableText } from 'common/components/DataTable/DataTableStyles';
import VarietyCell from 'common/components/DataTable/VarietyCell';
import { useCheckboxColumn } from 'common/components/DataTable/hooks/useCheckboxColumn';
import { MapParameters } from 'common/components/GoogleMapsModal/GmapsModalSlice';
import {
  LocationButton,
  multipleLocationTooltip,
  validateCoordinateInfo,
} from 'common/components/LocationButton/LocationButton';
import { Grower } from 'common/models';
import { CmsDate } from 'common/models/cmsDate';
import { BlockStatus, GrowerBlock, Variety } from 'common/models/growerBlock';
import { lightGreyText, orange } from 'common/styles/colors';
import { ForwardArrow } from 'features/grower-views/components/GrowerIcons';
import { StatusIcon } from 'features/grower-views/components/StatusIcon';
import { useMemo, useState } from 'react';
import { Column } from 'react-table';

export type BlockEvalTableItem = {
  blockStatus: BlockStatus;
  blockInfo: {
    id: number;
    blockId: string;
    grower: Partial<Grower>;
  };
  grower: Partial<Grower>;
  lotNumber: string;
  variety: Variety;
  latestEval: string | undefined;
  estimatedBins: number;
  binsToHarvest: number;
  harvestPhase: number;
  location: MapParameters | null;
};

export type UseBlockEvalTableData = (
  isLoading: boolean,
  agents?: GrowerBlock[],
) => {
  columns: Column<BlockEvalTableItem>[];
  data: BlockEvalTableItem[];
};

export const useBlockEvalTableData: UseBlockEvalTableData = (
  isLoading,
  growerBlocks = [],
) => {
  const [locations, setLocations] = useState<MapParameters[]>([]);
  const [locationsToShow, setLocationsToShow] = useState<MapParameters[]>([]);

  const locationsColumn: Column<BlockEvalTableItem> = useCheckboxColumn(
    'location',
    '80px',
    locations,
    setLocationsToShow,
    () => (
      <LocationButton
        backgroundColor={orange}
        iconColor={lightGreyText}
        mapParams={locationsToShow}
        disabledTooltip={multipleLocationTooltip}
      />
    ),
    isLoading,
  );

  const columns: Column<BlockEvalTableItem>[] = useMemo(
    () => [
      {
        accessor: 'blockStatus',
        width: '3%',
        Cell: ({ value: blockStatus }) => (
          <StatusIcon status={blockStatus} type='block' />
        ),
      },
      {
        accessor: 'grower',
        Header: 'Grower ID',
        Cell: ({ value: growerData }) => (
          <DataTableText>{`${growerData?.growerId}`}</DataTableText>
        ),
      },
      {
        accessor: 'blockInfo',
        Header: 'Block ID',
        Cell: ({ value: blockInfo }) => (
          <DataTableText>{`${blockInfo.blockId}`}</DataTableText>
        ),
      },
      {
        accessor: 'lotNumber',
        Header: 'Lot',
        Cell: ({ value: lotNumber }) => (
          <DataTableText>{lotNumber || '-'}</DataTableText>
        ),
      },
      {
        accessor: 'variety',
        Header: 'Variety',
        Cell: ({ value }) => <VarietyCell variety={value} />,
      },
      {
        accessor: 'latestEval',
        Header: 'Latest Eval.',
        width: '10%',
        Cell: ({ value }) => <DataTableText>{value ?? '-'}</DataTableText>,
      },
      {
        accessor: 'estimatedBins',
        Header: 'Latest Est.',
        Cell: ({ value }) => <DataTableText>{value || '0'}</DataTableText>,
      },
      {
        accessor: 'binsToHarvest',
        Header: 'Bins to Go',
        Cell: ({ value: binsToGo }) => (
          <DataTableText>{binsToGo || '0'}</DataTableText>
        ),
      },
      {
        accessor: 'harvestPhase',
        Header: '% Harvested',
        Cell: ({ value: harvestPhase }) => (
          <DataTableText>
            {harvestPhase ? `${harvestPhase}%` : '0'}
          </DataTableText>
        ),
      },
      locationsColumn,
      {
        id: 'Arrow',
        Header: '',
        width: '5%',
        Cell: () => (
          <span style={{ display: 'flex', justifyContent: 'center' }}>
            <ForwardArrow />
          </span>
        ),
      },
    ],
    [locationsColumn],
  );

  const data: BlockEvalTableItem[] = useMemo(
    () =>
      growerBlocks.map((block, index) => {
        const {
          id,
          blockStatus,
          grower,
          blockId,
          variety,
          lotNumber,
          latitude,
          longitude,
          evaluation,
          harvestEstimate,
        } = block;

        const location = validateCoordinateInfo(latitude, longitude, blockId);
        if (location) {
          setLocations(prev => {
            // When the index is zero, we know data is being reloaded (e.g., filter
            // applied or page change), so we take the chance to clear previous
            // locations, otherwise we would preserve stale selections. This is a
            // workaround for the inability to clear selections via useEffect.
            if (index === 0) return [location];
            return [...prev, location];
          });
        }

        return {
          blockInfo: { id, blockId, grower },
          blockStatus,
          grower,
          lotNumber,
          variety,
          latestEval: CmsDate.parse(evaluation?.evaluationDate)?.toString(),
          estimatedBins: harvestEstimate?.estimatedBins,
          binsToHarvest: harvestEstimate?.binsToHarvest,
          harvestPhase: harvestEstimate?.harvestPhase,
          location,
        };
      }),
    [growerBlocks],
  );

  return { columns, data };
};
