import { GetCountiesList } from 'common/api/countyApi';
import { GetDistrictList } from 'common/api/districtApi';
import { Option } from 'common/components/DetailControls';
import { Area, GrowerBlock, User, Variety } from 'common/models';
import { Size } from 'common/models/growerBlock/size';
import { UpdateBlockFormValues } from 'features/evaluation-views/types';
import { stringSortAscending } from 'utils/helperFunction';

type VarietyDeps = {
  subVarietyOptions: Option[];
  rootstockOptions: Option[];
};

/** Returns an alphabetized array of variety options with labels that combine
 * the variety name and code (ex: `Valencia | VAO`), unless otherwise specified.
 */
export function createVarietyOptions(
  varieties?: Variety[],
  codeOnly?: boolean,
): Option[] {
  return (varieties ?? [])
    .map(variety => ({
      id: variety.id,
      label: codeOnly
        ? variety.varietyCode
        : `${variety.varietyName} | ${variety.varietyCode}`,
    }))
    .sort((a, b) => stringSortAscending(a.label, b.label)) as Option[];
}

/** Returns an alphabetized array of size options if all options
 * are numbered strings. If options are letters, sorting is ignored.
 */
export function createSizeOptions(sizes: Size[]): Option[] | [] {
  return sizes
    .map(size => ({ id: size.id, label: size.value }))
    .sort((a, b) => {
      const sizeA = parseInt(a.label, 10);
      const sizeB = parseInt(b.label, 10);
      return Number.isNaN(sizeA) && Number.isNaN(sizeB) ? 0 : sizeA - sizeB;
    });
}

/** Returns an object with the following variety dependencies:
 *  - `subVarietyOptions`
 *  - `rootstockOptions`
 *
 * See {@link VarietyDeps}.
 *
 * Both properties contain an alphabetized array of options.
 * @param {Variety} selectedVariety the variety object containing the dependencies;
 * can be `undefined`
 */
export function createVarietyDependencies(
  selectedVariety: Variety | undefined,
): VarietyDeps {
  const result = {
    subVarietyOptions: [] as Option[],
    rootstockOptions: [] as Option[],
  };

  if (selectedVariety) {
    result.subVarietyOptions = selectedVariety.subVarieties
      .map(subVariety => ({
        id: subVariety.id,
        label: `${subVariety.subVarietyCode}`,
      }))
      .sort((a, b) => stringSortAscending(a.label, b.label));

    result.rootstockOptions = selectedVariety.rootstocks
      .map(rootstock => ({
        id: rootstock.id,
        label: `${rootstock.rootstockCode}`,
      }))
      .sort((a, b) => stringSortAscending(a.label, b.label));
  }
  return result;
}

/** Returns an alphabetized array of evaluator options with a label
 *  constructed from the first and last name of a user.
 */
export function createEvaluatorOptions(evaluators: User[] | []): Option[] {
  return evaluators
    .map(evltr => ({
      id: evltr.id,
      label: `${evltr.firstName} ${evltr.lastName}`,
    }))
    .sort((a, b) => stringSortAscending(a.label, b.label));
}

/** Returns an alphabetized array of county options. */
export function createCountyOptions(counties: GetCountiesList | []): Option[] {
  return counties
    .map(county => ({ id: county.id, label: county.countyName }))
    .sort((a, b) => stringSortAscending(a.label, b.label));
}

/** Returns an ordered array of district options. */
export function createDistrictOptions(
  districts: GetDistrictList | [],
): Option[] {
  return districts
    .map(district => ({
      id: district.id,
      label: district.districtId.toString(),
    }))
    .sort((a, b) => stringSortAscending(a.label, b.label));
}

/** Returns an alphabetized array of area options. */
export function createAreaOptions(areas: Area[]): Option[] {
  return areas
    .map(area => ({
      id: area.id,
      label: area.areaName,
    }))
    .sort((a, b) => stringSortAscending(a.label, b.label));
}

/** Returns default values for the block details form */
export const createBlockFormValues = (
  blockData: GrowerBlock | undefined,
): UpdateBlockFormValues => {
  if (blockData) {
    const {
      lotNumber,
      variety,
      primaryEvaluator,
      secondaryEvaluator,
      subVariety,
      rootstock,
      county,
      district,
      area,
      acres,
      trees,
      yearPlanted,
      latitude,
      longitude,
      notes,
    } = blockData;

    return {
      lotNumber,
      variety,
      primaryEvaluator,
      secondaryEvaluator,
      subVariety,
      rootstock,
      county,
      district,
      area,
      acres,
      trees,
      yearPlanted,
      latitude,
      longitude,
      notes,
    };
  }

  return {
    lotNumber: null,
    variety: null,
    primaryEvaluator: null,
    secondaryEvaluator: null,
    subVariety: null,
    rootstock: null,
    county: null,
    district: null,
    area: null,
    acres: null,
    trees: null,
    yearPlanted: null,
    latitude: null,
    longitude: null,
    notes: null,
  };
};
