import { ErrorBoundary } from '@sentry/react';
import { Routes as AuthRoutes } from 'features/auth/Routes';
import { PrivateRoute } from 'features/auth/components/privateRoute.tsx';
import { UserRoutes } from 'features/user-views/Routes';
import { GrowerRoutes } from 'features/grower-views/Routes';
import { EvalEstimatesRoutes } from 'features/evaluation-views/Routes';
import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from '../GlobalStyle';
import AppTheme from 'utils/styleValues';
import { ChangePasswordPage } from 'features/user-views/pages/ChangePasswordView';
import { NotificationContainer } from 'common/components/Notification';
import { NotFoundView } from 'common/components/NotFound';
import { ConfirmationModal } from 'features/confirmation-modal';
import Splash from 'common/components/Splash';
import TokenLayout from 'common/components/HolyGrailLayout/components/TokenLayout';
import SettingsView from 'features/settings-view/SettingsView';
import { RoleType } from 'common/models';
import { Constants } from 'utils/constants';
import { PackPlanRoutes } from 'features/pack-plan-views/Routes';
import { PickScheduleRoutes } from 'features/pick-schedule-views/Routes';
import { HarvestPayrollRoutes } from 'features/harvest-payroll/Routes';
import { GoogleMapsModal } from 'common/components/GoogleMapsModal/GoogleMapsModal';

export const App: FC = () => (
  <ErrorBoundary>
    <ThemeProvider theme={AppTheme}>
      <ConfirmationModal />
      <GoogleMapsModal />
      <NotificationContainer />
      <Switch>
        <Route exact path='/' component={Splash} />
        <Route path='/auth' component={AuthRoutes} />
        <TokenLayout>
          <Switch>
            <PrivateRoute
              exact
              path='/user/change-password/:id'
              component={ChangePasswordPage}
            />
            <PrivateRoute
              exact
              path='/settings'
              component={SettingsView}
              requiredRoles={[RoleType.Admin]}
            />
            <Route
              path={`${Constants.routes.USERS_LIST}`}
              component={UserRoutes}
            />
            <Route
              path={`${Constants.routes.GROWERS_LIST}`}
              component={GrowerRoutes}
            />
            <Route
              path={`${Constants.routes.EVAL_EST_MAIN}`}
              component={EvalEstimatesRoutes}
            />
            <Route
              path={`${Constants.routes.PACK_PLAN}`}
              component={PackPlanRoutes}
            />
            <Route
              path={Constants.routes.PICK_SCHEDULE}
              component={PickScheduleRoutes}
            />
            <Route
              path={Constants.routes.HARVEST_PAYROLL}
              component={HarvestPayrollRoutes}
            />
            <Route component={NotFoundView} />
          </Switch>
        </TokenLayout>
        <Route component={NotFoundView} />
      </Switch>
    </ThemeProvider>
    <GlobalStyle />
  </ErrorBoundary>
);
