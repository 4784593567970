import { FC } from 'react';
import { useStatus } from '../hooks/useStatus';

export const StatusIcon: FC<{
  status: number; // Enum number key.
  type: 'grower' | 'block';
}> = ({ status, type }) => {
  const { createStatusProps } = useStatus();

  const { icon } = createStatusProps(status, type, true);

  return icon;
};
