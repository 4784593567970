import { FC, Fragment } from 'react';
import {
  PayrollTableRow,
  emptyIdKey,
  emptyPayrollRow,
  picksHeaders,
  transfersHeaders,
} from 'features/harvest-payroll/utils/payrollDataTypes';
import {
  TotalPrompt,
  TableContainer,
  Total,
  EmptyHeaderCell,
  EmptyTotalCell,
  NoTransfersCell,
  FormHeader,
} from './styles';
import { PayrollTableLine } from './PayrollTableLine';
import {
  PayrollPageState,
  payrollSlice,
  payrollSliceName,
} from 'features/harvest-payroll/PayrollSlice';
import { useAppDispatch, useAppSelector } from 'app/redux';
import { createRowKey } from 'features/harvest-payroll/utils/payrollPageUtils';
import { useSavePayroll } from 'features/harvest-payroll/hooks/useSavePayroll';
import {
  HarvestLineType,
  HaulingLineType,
  TransferLineType,
} from 'common/models/harvestData/payrollData';
import { AddPickLineForm } from './AddCustomLineForm/AddPickLineForm';
import { AddTransferLineForm } from './AddCustomLineForm/AddTransferLineForm';
import { FormButtonHeader } from 'common/components/FormHeader/FormButtonHeader';

/** Component that handles the display of either the picks or transfers table  */
export const PayrollTable: FC<{
  tableData: PayrollTableRow[];
  tableTotalRow: PayrollTableRow;
  /** When `true`, the the payroll form edit button displays. Default is `false`. */
  isPicksTable?: boolean;
  /** Function to reset table data to its origininal state */
  setInitialTableData?: () => void;
}> = ({
  isPicksTable = true,
  tableData,
  tableTotalRow,
  setInitialTableData,
}) => {
  const dispatch = useAppDispatch();
  const { handleSavePayroll } = useSavePayroll();
  const { isEditable, isDirty } = useAppSelector<PayrollPageState>(
    state => state[payrollSliceName],
  );

  const handleEditClick = async () => {
    if (isEditable && isDirty) {
      const isSuccess = await handleSavePayroll();
      if (!isSuccess) {
        return;
      }
    }
    dispatch(payrollSlice.actions.setIsEditable(!isEditable));
  };

  const handleCancelClick = () => {
    if (setInitialTableData && isDirty) {
      setInitialTableData();
      dispatch(payrollSlice.actions.setIsDirty(false));
    }
    dispatch(payrollSlice.actions.setIsEditable(false));
  };

  const EmptyRow: FC<{ rowData: PayrollTableRow }> = ({ rowData }) => (
    <PayrollTableLine
      key={createRowKey(rowData.metadata)}
      row={rowData}
      changeBackground={false}
    />
  );

  const PickRows: FC = () => {
    let defaultQuantity = '0';
    let defaultCtrRecId: number | null;
    let defaultHaulRecIds: {
      // Either 'ctrRecordId' is defined and 'hlrRecordId' is 'null' or vice-versa.
      contractorRecordId: number | null;
      haulerRecordId: number | null;
    };
    let haulRate = '0';
    let surchargeRate = '0';

    /**
     * Function to provide the default quantity and the record ID to be used
     * when creating a new line.
     */
    const determineNewLineData = (row: PayrollTableRow) => {
      const {
        metadata: { contractorRecordId, haulerRecordId, descType },
        quantity,
        rate,
      } = row;

      if (descType === HarvestLineType[HarvestLineType.Harvesting]) {
        defaultQuantity = quantity;
        defaultCtrRecId = contractorRecordId as number;
      }

      if (descType === HaulingLineType[HaulingLineType.Hauling]) {
        defaultQuantity = quantity;
        defaultHaulRecIds = { contractorRecordId, haulerRecordId };
        haulRate = rate;
      }

      if (descType === HaulingLineType[HaulingLineType.Fuel_Surcharge]) {
        surchargeRate = rate;
      }

      return { defaultQuantity, defaultCtrRecId, defaultHaulRecIds };
    };

    return tableData.map(row => {
      const isEmptyRow = row.metadata.descType === emptyIdKey;
      const changeBackground =
        row.metadata.descCategory === HaulingLineType[HaulingLineType.Hauling];

      const { defaultQuantity, defaultCtrRecId, defaultHaulRecIds } =
        determineNewLineData(row);

      return isEmptyRow ? (
        <Fragment key={createRowKey(row.metadata)}>
          {isEditable ? (
            <AddPickLineForm
              pickId={row.metadata.pickId}
              defaultCtrRecId={defaultCtrRecId}
              defaultHaulRecIds={defaultHaulRecIds}
              defaultQuantity={defaultQuantity}
              rates={{ haulRate, surchargeRate }}
            />
          ) : (
            <EmptyRow rowData={row} />
          )}
        </Fragment>
      ) : (
        <PayrollTableLine
          key={createRowKey(row.metadata)}
          row={row}
          changeBackground={changeBackground}
        />
      );
    });
  };

  const TransferRows: FC = () => {
    let changeBackground = true;

    return (
      <>
        {tableData.length ? (
          tableData.map(row => {
            if (
              row.metadata.descType ===
              TransferLineType[TransferLineType.Transfer]
            ) {
              changeBackground = !changeBackground;
            }

            return (
              <Fragment key={createRowKey(row.metadata)}>
                <PayrollTableLine
                  row={row}
                  changeBackground={changeBackground}
                />
              </Fragment>
            );
          })
        ) : (
          <NoTransfersCell>No transfers entered.</NoTransfersCell>
        )}
        {isEditable ? (
          <AddTransferLineForm />
        ) : (
          <EmptyRow rowData={emptyPayrollRow} />
        )}
      </>
    );
  };

  return isPicksTable ? (
    <>
      <FormHeader>
        <h4>Picks</h4>
        <FormButtonHeader
          isEditable={isEditable}
          handleCancelClick={handleCancelClick}
          handleEditClick={handleEditClick}
        />
      </FormHeader>
      <TableContainer>
        {picksHeaders.map(header => (
          <EmptyHeaderCell key={`${header.label}`}>
            {header.label}
          </EmptyHeaderCell>
        ))}
        <EmptyHeaderCell />
        <PickRows />
        <TotalPrompt>{tableTotalRow.rate}</TotalPrompt>
        <Total>{tableTotalRow.subtotal}</Total>
        <EmptyTotalCell />
      </TableContainer>
    </>
  ) : (
    <>
      <FormHeader>
        <h4>Transfers</h4>
      </FormHeader>
      <TableContainer>
        {transfersHeaders.map(header => (
          <EmptyHeaderCell key={`${header.label}`}>
            {header.label}
          </EmptyHeaderCell>
        ))}
        <EmptyHeaderCell />
        <TransferRows />
        <TotalPrompt>{tableTotalRow.rate}</TotalPrompt>
        <Total>{tableTotalRow.subtotal}</Total>
        <EmptyTotalCell />
      </TableContainer>
    </>
  );
};
