import { FC } from 'react';
import styled from 'styled-components';
import { useRbac } from 'features/rbac';
import { BlockEvalTable } from '../components/BlockEvalTable';
import {
  useGetBlocksByNeedEvaluationQuery,
  useGetBlocksByBlankEvaluationsQuery,
  useGetBlocksByOtherEvaluationsQuery,
} from 'common/api/growerBlockApi';
import { PSFKeys } from 'utils/hooks/usePSFState';
import { VarietalSumTable } from '../components/VarietalSummaryTable';

const Divider = styled.div`
  height: 20px;
`;

export const allEvalTableTitle = 'All Evaluations';
export const myBocksTableTitle = 'My Blocks/Evaluations';

export const EvalEstMainView: FC = () => {
  const { userHasPermission } = useRbac();

  return (
    <>
      {userHasPermission('myBlocks/EvalsTable:read') && (
        <>
          <BlockEvalTable
            title={myBocksTableTitle}
            blockQuery={useGetBlocksByNeedEvaluationQuery}
            psfKey={PSFKeys.MyEvals}
          />
          <Divider />
        </>
      )}
      {userHasPermission('allEvals/EvalsTable:read') && (
        <>
          <BlockEvalTable
            title={allEvalTableTitle}
            blockQuery={useGetBlocksByOtherEvaluationsQuery}
            psfKey={PSFKeys.AllEvals}
          />
          <Divider />
        </>
      )}
      {userHasPermission('blankEvals/EvalsTable:read') && (
        <>
          <BlockEvalTable
            title='Blank Evaluations'
            blockQuery={useGetBlocksByBlankEvaluationsQuery}
            psfKey={PSFKeys.BlankEvals}
          />
          <Divider />
        </>
      )}
      {userHasPermission('otherEvals/EvalsTable:read') && (
        <>
          <BlockEvalTable
            title='Other Evaluations'
            blockQuery={useGetBlocksByOtherEvaluationsQuery}
            psfKey={PSFKeys.OtherEvals}
          />
          <Divider />
        </>
      )}
      <VarietalSumTable title='Varietal Summary' />
    </>
  );
};
