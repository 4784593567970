import {
  useGetGrowerBlockByIdQuery,
  useUpdateGrowerBlockMutation,
} from 'common/api/growerBlockApi';
import { handleError } from 'common/api/handleError';
import { WithLoadingOverlay } from 'common/components/LoadingSpinner';
import { FC, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Constants } from 'utils/constants';
import { BlockDetailForm } from '../components/BlockDetailsForm';
import { GrowerBlock } from 'common/models';

export const BlockDetailView: FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [updateGrowerBlock] = useUpdateGrowerBlockMutation();
  const {
    data: block,
    isLoading: isLoadingGrowerBlock,
    error: getError,
  } = useGetGrowerBlockByIdQuery(id);

  const callUpdateGrowerBlock = useCallback(
    async (data: GrowerBlock) => {
      const updateRequest: GrowerBlock = {
        ...data,
      };
      updateRequest.id = parseInt(id, 10);
      await updateGrowerBlock(updateRequest).unwrap();
    },
    [id, updateGrowerBlock],
  );

  useEffect(() => {
    if (getError) {
      handleError(getError, 'Unable to load grower. Returning to grower list.');
      history.replace(Constants.routes.GROWERS_LIST);
    }
  }, [getError, history]);

  return (
    <WithLoadingOverlay isLoading={isLoadingGrowerBlock}>
      {block && (
        <BlockDetailForm block={block} updater={callUpdateGrowerBlock} />
      )}
    </WithLoadingOverlay>
  );
};
