import { LoadingButton } from 'common/components/LoadingButton';
import { SubmitButton } from 'common/styles/button';
import styled from 'styled-components';
import { lighterNavy, orange, white } from 'common/styles/colors';
import { BlockStatus, GrowerBlock, ReasonEnum } from 'common/models';
import { mobile } from 'common/styles/breakpoints';
import { Dispatch, SetStateAction, useState } from 'react';
import { useUpdateBlockStatusMutation } from 'common/api/growerBlockApi';
import * as notifier from 'common/services/notification';
import { useHistory, useParams } from 'react-router-dom';
import { handleError } from 'common/api/handleError';
import { Constants } from 'utils/constants';
import { MakeActiveModal } from './modals/MakeActiveModal';
import { MakeInactiveButton } from './modals/MakeInactiveButton';
import { UpdateBlockStatusRequest } from 'common/api/growerBlockApiTypes';

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 100%;

  @media (max-width: ${mobile}) {
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
`;

const ViewDataButton = styled.button`
  font-family: 'KanitRegular';
  font-size: 18px;
  letter-spacing: 0.12em;
  width: 336px;
  background: ${lighterNavy};
  border-radius: 4px;
  margin-bottom: 40px;
`;

export const ToggleModalButton = styled.button`
  font-family: 'KanitRegular';
  font-size: 18px;
  letter-spacing: 0.12em;
  border-radius: 4px;
  border: none;
  color: ${white};

  &.make-inactive-btn {
    background: ${orange};
    width: 200px;
    height: 40px;
  }

  &.make-active-btn {
    background: ${lighterNavy};
    width: 180px;
    height: 40px;
  }
`;

interface FooterProps {
  block: GrowerBlock | undefined;
  openInactiveModal: Dispatch<SetStateAction<boolean>>;
}

export const BlockDetailsFooter: React.FC<FooterProps> = ({
  block,
  openInactiveModal,
}) => {
  const blockStatus = block ? block.blockStatus : undefined;
  const { id } = useParams<{ id: string }>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [updateBlockStatus] = useUpdateBlockStatusMutation();
  const history = useHistory();
  const closeModal = () => {
    setIsOpen(false);
  };
  const openModal = () => {
    setIsOpen(true);
  };

  const makeBlockActive = async () => {
    try {
      setIsLoading(true);
      await updateBlockStatus({
        id: parseInt(id, 10),
        blockStatus: BlockStatus.Pending,
        reason: ReasonEnum.None,
        notes: '',
      } as UpdateBlockStatusRequest).unwrap();
      setIsLoading(false);
      openModal();
      notifier.showSuccessMessage(
        Constants.errorMessages.UPDATE_STATUS_SUCCESS,
      );
    } catch (error) {
      handleError(error, Constants.errorMessages.UPDATE_STATUS_FAILED);
      setIsLoading(false);
    }
  };

  const goToEvalEstPage = () => {
    history.push(
      `${Constants.routes.EVAL_EST_MAIN}/${block?.id}${Constants.routes.ESTIMATES}`,
    );
  };

  return blockStatus === BlockStatus.Withdrawn ? (
    <></>
  ) : (
    <>
      <MakeActiveModal
        isOpen={isOpen}
        closeModal={closeModal}
        contentProps={{
          growerName: block?.grower?.name || '',
          blockName: block?.blockName || '',
          evaluatorName:
            block?.primaryEvaluator?.firstName &&
            block?.primaryEvaluator?.lastName
              ? `${block?.primaryEvaluator?.firstName} ${block?.primaryEvaluator?.lastName}`
              : '',
        }}
      />
      <ButtonGroup>
        {blockStatus === BlockStatus.Active && (
          <ViewDataButton
            as={SubmitButton}
            loading='false'
            onClick={goToEvalEstPage}
          >
            VIEW ESTIMATES
          </ViewDataButton>
        )}

        {(blockStatus === BlockStatus.New ||
          blockStatus === BlockStatus.Active ||
          blockStatus === BlockStatus.Pending) && (
          <MakeInactiveButton openModal={openInactiveModal} />
        )}
        {blockStatus === BlockStatus.Non$Producing && (
          <>
            <LoadingButton
              as={ToggleModalButton}
              className='make-active-btn'
              type='button'
              loading={isLoading}
              onClick={makeBlockActive}
            >
              MAKE ACTIVE
            </LoadingButton>
          </>
        )}
      </ButtonGroup>
    </>
  );
};
