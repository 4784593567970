import styled from 'styled-components';
import { mobile } from 'common/styles/breakpoints';
import { useStatus } from 'features/grower-views/hooks/useStatus';
import { lighterNavy, red } from 'common/styles/colors';
import { InactiveReason, ReasonEnum } from 'common/models';
import { toEnumLabel } from 'utils/enumFunctions';

const HeaderWrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const StatusBar = styled.div`
  background-color: ${props => props.color};
  height: 10px;
`;

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${mobile}) {
    margin: 0 5px;
  }
`;

const StatusText = styled.p`
  color: ${props => props.color};
  font-family: 'KanitBold';
  font-weight: 600;
  font-size: 20px;
  margin: 5px;
`;

const ReasonText = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;

  h6 {
    color: ${red};
    font-family: 'KanitSemiBold';
    font-weight: 600;
    font-size: 18px;
    margin: 0;
  }

  span {
    color: ${lighterNavy};
    font-family: 'KanitRegular';
    font-size: 18px;
    margin-left: 10px;
  }

  @media (max-width: ${mobile}) {
    margin-left: 10px;
  }
`;

export const DetailsHeader: React.FC<{
  status: number;
  type: 'grower' | 'block';
  inactiveReason?: InactiveReason;
}> = ({ status, type, inactiveReason }) => {
  const { createStatusProps } = useStatus();
  const { color, text, icon } = createStatusProps(status, type, false);

  return (
    <HeaderWrapper className='header-wrapper'>
      <StatusBar color={color} />
      <StatusWrapper className='status-wrapper'>
        {icon}
        <StatusText color={color}>{`${text} ${
          inactiveReason?.reason
            ? `- ${toEnumLabel(ReasonEnum[inactiveReason.reason])}`
            : ''
        }`}</StatusText>
      </StatusWrapper>
      {inactiveReason?.note && (
        <ReasonText>
          <h6>Note:</h6>
          <span>{inactiveReason?.note}</span>
        </ReasonText>
      )}
    </HeaderWrapper>
  );
};
