import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { handleError } from 'common/api/handleError';
import {
  CreateOrViewWrapper,
  DetailText,
} from 'common/components/DetailControls';
import { ResponsiveRow, StyledStack as Stack } from 'common/components/FormRow';
import { WithLoadingOverlay } from 'common/components/LoadingSpinner';
import { EstimateLog } from 'common/models/growerBlock/estimateLog';
import { HarvestEstimate } from 'common/models/growerBlock/harvestEstimate';
import { useRbac } from 'features/rbac';
import { mobile } from 'common/styles/breakpoints';
import { FC, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { EstimatesHistoryTable } from './EstimatesHistoryTable';

const StyledRow = styled(ResponsiveRow).attrs(() => ({
  className: 'styled-row',
}))`
  div.col > div:first-of-type {
    div:first-of-type {
      width: 50%;
    }
  }
`;

const StyledStack = styled(Stack)`
  @media screen and (max-width: ${mobile}) {
    .row > div:first-child {
      width: 50%;
    }
  }
`;

export type EstimatesFieldsProps = {
  data: HarvestEstimate | undefined;
  isLoading: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  estimates: EstimateLog[] | undefined;
  conversionRate: number | null | undefined;
};

export const EstimatesFields: FC<EstimatesFieldsProps> = ({
  data: harvestEstimate,
  isLoading: isLoadingHarvestEstimate,
  error: harvestError,
  estimates,
  conversionRate,
}) => {
  const { userHasPermission } = useRbac();
  const {
    formState: { errors },
  } = useFormContext<HarvestEstimate>();
  const history = useHistory();

  useEffect(() => {
    if (harvestError) {
      handleError(
        harvestError,
        'Unable to load latest harvest information. Please refresh to try again.',
      );
      history.goBack();
    }
  }, [harvestError, history]);

  return (
    <WithLoadingOverlay isLoading={isLoadingHarvestEstimate}>
      <StyledStack>
        <CreateOrViewWrapper
          formObject={
            harvestEstimate && Object.keys(harvestEstimate).length !== 0
          }
          message='No harvest estimates exist yet.'
          canCreate={userHasPermission('evalEstTabs:create/edit')}
        >
          <StyledRow>
            <Col>
              <DetailText
                label='Latest Estimate Bins'
                name='estimatedBins'
                validation={errors.estimatedBins?.message}
              />
            </Col>
            <Col>
              <DetailText
                label='Latest Estimate Date'
                name='estimateDate'
                editable={false}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col>
              <DetailText
                label='Bins Picked'
                name='binsPicked'
                editable={false}
              />
            </Col>
            <Col>
              <DetailText
                label='Latest Harvest Date'
                name='harvestDateForEstimate'
                editable={false}
              />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col>
              <DetailText
                label='Bins to Harvest'
                name='binsToHarvest'
                editable={false}
              />
            </Col>
            <Col />
          </StyledRow>
          <EstimatesHistoryTable
            conversionRate={conversionRate}
            estimates={estimates}
          />
        </CreateOrViewWrapper>
      </StyledStack>
    </WithLoadingOverlay>
  );
};
