import { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useNavLinks } from '../hooks/useNavLinks';
import { LinksWrapper, StyledNavLink, SubNavLink } from './NavMenu';
import { MobileNavLink } from './MobileNavLink';
import { useLogoutModal } from '../hooks/useLogoutModal';
import { SettingsButtonComponent } from './SettingsButton';
import { LogoutButtonComponent } from './LogoutButton';
import { MobileScrollWrapper } from 'common/styles/page';

const ButtonDiv = styled.div`
  padding: 15px;
  display: flex;
  justify-content: space-between;
`;

type expandState = { id: number; expanded: boolean };

interface Props {
  callback?: React.MouseEventHandler<HTMLElement>;
}

const NavMenu = ({ callback }: Props): JSX.Element => {
  const { pathname } = useLocation();
  const navLinks = useNavLinks();
  const [expand, toggleExpand] = useState<expandState[]>([]);
  const { openLogoutModal } = useLogoutModal();

  useEffect(() => {
    const initialState: expandState[] = [];
    navLinks.forEach(link => {
      if (link.subLinks && link.subLinks.length > 0)
        initialState.push({ id: link.id, expanded: false });
    });

    toggleExpand(initialState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleCollapse = (id: number) => {
    toggleExpand(prevState => {
      const newState = JSON.parse(JSON.stringify(prevState));
      for (let i = 0; i < newState.length; i += 1) {
        if (newState[i].id === id) {
          newState[i].expanded = !newState[i].expanded;
        }
      }
      return newState;
    });
  };

  return (
    <LinksWrapper>
      <MobileScrollWrapper>
        {navLinks.map(link => (
          <Fragment key={link.id}>
            <StyledNavLink key={link.id}>
              <MobileNavLink
                link={link}
                active={pathname.startsWith(link.path)}
                onClick={callback}
                hasSublinks={link.subLinks && link.subLinks.length > 0}
                expanded={
                  expand.find(object => object.id === link.id)?.expanded
                }
                toggle={toggleCollapse}
                id={link.id}
              />
            </StyledNavLink>
            {/* change next line to check if state for this is is true/false */}
            {expand.find(object => object.id === link.id)?.expanded
              ? link.subLinks &&
                link.subLinks?.map(subLink => (
                  <SubNavLink key={subLink.id}>
                    <MobileNavLink link={subLink} child onClick={callback} />
                  </SubNavLink>
                ))
              : null}
          </Fragment>
        ))}
      </MobileScrollWrapper>
      <ButtonDiv>
        <SettingsButtonComponent onClick={callback} />
        <LogoutButtonComponent onClick={openLogoutModal} />
      </ButtonDiv>
    </LinksWrapper>
  );
};

export default NavMenu;
