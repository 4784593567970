import { DetailDropDown, StyledText } from 'common/components/DetailControls';
import { CreateOrViewWrapper } from 'common/components/DetailControls/CreateOrViewWrapper';
import { DetailControl } from 'common/components/DetailControls/DetailControl';
import DetailDatePicker from 'common/components/DetailControls/DetailDatePicker';
import { ResponsiveRow, StyledStack } from 'common/components/FormRow';
import { Evaluation, GibbRate } from 'common/models/evaluation';
import { UpdateEvalFormValues } from 'features/evaluation-views/types';
import { useRbac } from 'features/rbac';
import { FC, useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import {
  getNumberedOptions,
  gibbOptions,
  gibbTrueOption,
  gibbFalseOption,
  gibbRateOptions,
  marketOptions,
} from 'utils/formValues/evaluation';

const StyledRow = styled(ResponsiveRow).attrs(() => ({
  className: 'styled-row',
}))``;

const LastCol = styled(Col)`
  @media (min-width: 768px) {
    flex: none;
    width: 50%;
    display: inline-block;
  }
`;

interface EvaluationProps {
  evaluation: Evaluation | undefined;
}

export const EvaluationFields: FC<EvaluationProps> = ({ evaluation }) => {
  const numberedOptions = getNumberedOptions(1, 4);
  const [rateDisabled, setRateDisabled] = useState(!evaluation?.gibb);
  const { userHasPermission } = useRbac();
  const {
    control,
    formState: { errors },
    register,
    setValue,
    trigger,
    watch,
  } = useFormContext<UpdateEvalFormValues>();

  const watchGibb = watch('gibb');

  useEffect(() => {
    // disable gibb rate dropdown if gibb is false
    if (watchGibb?.label === gibbFalseOption.label) {
      setRateDisabled(true);
      setValue('gibbRate', null, {
        shouldDirty: false,
        shouldTouch: false,
      });
      // if error for gibb rate is present, remove it by calling validation
      if (errors.gibbRate) trigger('gibbRate');
    } else {
      setRateDisabled(false);
    }
  }, [errors.gibbRate, setValue, trigger, watchGibb]);

  const { ref: evaluationDateRef, ...evaluationDateProps } =
    register('evaluationDate');
  const { ref: colorRef, ...colorProps } = register('color');
  const { ref: marketRef, ...marketProps } = register('market');
  const { ref: gibbRef, ...gibbProps } = register('gibb');
  const { ref: dropRef, ...dropProps } = register('drop');
  const { ref: gibbRateRef, ...gibbRateProps } = register('gibbRate');
  const { ref: priorityRef, ...priorityProps } = register('priority');
  const { ref: conditionRef, ...conditionProps } = register('condition');

  return (
    <CreateOrViewWrapper
      formObject={evaluation}
      message='No evaluation exists yet.'
      canCreate={userHasPermission('evalEstTabs:create/edit')}
    >
      <StyledStack>
        <StyledRow>
          <Col>
            <DetailDatePicker
              label='Eval. Date'
              control={control}
              {...evaluationDateProps}
              placeholderText='Select...'
            />
          </Col>
          <Col>
            <DetailDropDown
              label='Market'
              defaultValue={
                marketOptions.find(opt => opt.label === evaluation?.market) ??
                null
              }
              textValue={
                marketOptions.find(opt => opt.label === evaluation?.market)
                  ?.label ?? ''
              }
              {...marketProps}
              options={marketOptions}
              optionTransformer={opt => opt.label}
              formControl={control}
            />
          </Col>
        </StyledRow>
        <StyledRow>
          <Col>
            <DetailControl
              label='Harv. Phase'
              largeLabel={false}
              labelMax={undefined}
              controlId={undefined}
              isViewMode
              valueRenderer={id => (
                <StyledText id={id} value='TBD' readOnly plaintext />
              )}
            />
          </Col>
          <Col>
            <DetailDropDown
              label='Color'
              defaultValue={
                evaluation?.color
                  ? { id: evaluation.color, label: evaluation.color.toString() }
                  : null
              }
              textValue={evaluation?.color ? evaluation.color.toString() : ''}
              {...colorProps}
              options={numberedOptions}
              optionTransformer={opt => opt.id}
              formControl={control}
            />
          </Col>
        </StyledRow>
        <StyledRow>
          <Col>
            {/*
              No 'optionTransformer' attribute is used here because 'DetailDropdown'
              ignores the boolean 'false'. We use the type 'Option' until after
              form submission where we convert the option to a boolean.
            */}
            <DetailDropDown
              label='Gibb'
              defaultValue={evaluation?.gibb ? gibbTrueOption : gibbFalseOption}
              textValue={
                evaluation?.gibb ? gibbTrueOption.label : gibbFalseOption.label
              }
              {...gibbProps}
              options={gibbOptions}
              formControl={control}
            />
          </Col>
          <Col>
            <DetailDropDown
              label='Drop'
              defaultValue={
                evaluation?.drop
                  ? { id: evaluation.drop, label: evaluation.drop.toString() }
                  : null
              }
              textValue={evaluation?.drop ? evaluation.drop.toString() : ''}
              {...dropProps}
              options={numberedOptions}
              optionTransformer={opt => opt.id}
              formControl={control}
            />
          </Col>
        </StyledRow>
        <StyledRow>
          <Col>
            <DetailDropDown
              label='Gibb Rate'
              defaultValue={
                evaluation?.gibbRate
                  ? {
                      id: evaluation.gibbRate,
                      label: GibbRate[evaluation.gibbRate],
                    }
                  : null
              }
              textValue={
                evaluation?.gibbRate ? GibbRate[evaluation.gibbRate] : ''
              }
              {...gibbRateProps}
              options={gibbRateOptions}
              optionTransformer={opt => opt.id}
              formControl={control}
              isDisabled={rateDisabled}
              // if gibb rate is disabled, an empty string is shown instead of 'Select...'
              placeholder={rateDisabled ? '' : undefined}
            />
          </Col>
          <Col>
            <DetailDropDown
              label='Priority'
              defaultValue={
                evaluation?.priority
                  ? {
                      id: evaluation.priority,
                      label: evaluation.priority.toString(),
                    }
                  : null
              }
              textValue={
                evaluation?.priority ? evaluation.priority.toString() : ''
              }
              {...priorityProps}
              options={numberedOptions}
              optionTransformer={opt => opt.id}
              formControl={control}
            />
          </Col>
        </StyledRow>
      </StyledStack>
      <StyledRow>
        <LastCol>
          <DetailDropDown
            label='Condition'
            defaultValue={
              evaluation?.condition
                ? {
                    id: evaluation.condition,
                    label: evaluation.condition.toString(),
                  }
                : null
            }
            textValue={
              evaluation?.condition ? evaluation.condition.toString() : ''
            }
            {...conditionProps}
            options={numberedOptions}
            optionTransformer={opt => opt.id}
            formControl={control}
          />
        </LastCol>
      </StyledRow>
    </CreateOrViewWrapper>
  );
};
