import { FC } from 'react';
import { GrowerBlock, Variety } from 'common/models';
import { Size } from 'common/models/growerBlock/size';
import { PackHouse } from 'common/models/packHouse';
import { AvailBlockToPick } from 'features/pick-schedule-views/types';
import { useFormContext } from 'react-hook-form';
import {
  InfoLabel,
  LightGreyNotes,
  SummarySection,
  WizardDivider,
  WizardRow,
} from '../WizardComponents/wizardStyles';
import { WizardHeader } from '../WizardComponents/WizardHeader';
import { blueShade, lightGreyText, orange } from 'common/styles/colors';
import { BlueShadeInfoValue } from './styles';
import { validateCoordinateInfo } from 'common/components/LocationButton/LocationButton';

export const WizardInfoSection: FC<{
  step: number;
  availBlock?: AvailBlockToPick;
  adHocBlock?: GrowerBlock;
  binsToGo: number;
  packHouse: PackHouse | undefined;
  variety: Variety | undefined;
  size: Size | undefined;
}> = ({ step, availBlock, adHocBlock, binsToGo, packHouse, variety, size }) => {
  const { getValues } = useFormContext();
  const mapParams = () => {
    const block = adHocBlock || availBlock;

    return validateCoordinateInfo(
      block?.latitude,
      block?.longitude,
      block?.blockId,
    );
  };

  return (
    <>
      <WizardHeader
        growerId={availBlock?.growerId || adHocBlock?.grower?.growerId}
        blockId={availBlock?.blockId || adHocBlock?.blockId}
        varietyCode={variety?.varietyCode || adHocBlock?.variety.varietyCode}
        evaluator={
          availBlock?.evaluator ||
          `${adHocBlock?.primaryEvaluator?.firstName} ${adHocBlock?.primaryEvaluator?.lastName}`
        }
        binsRemaining={binsToGo}
        size={adHocBlock?.sizeEstimates
          .map(estimate => (estimate.percentValue ? estimate.size.value : ''))
          .filter(size => !!size)
          .join('/')}
        mapParams={mapParams()}
        locationStyles={{ backgroundColor: orange, iconColor: lightGreyText }}
        textColor={blueShade}
        showSize={!!adHocBlock}
      />
      {adHocBlock && step === 1 ? (
        <WizardDivider />
      ) : (
        <SummarySection>
          {availBlock && (
            <>
              <WizardRow>
                <InfoLabel>Facility</InfoLabel>
                <BlueShadeInfoValue>{packHouse?.code}</BlueShadeInfoValue>
              </WizardRow>
              <WizardRow>
                <InfoLabel>Pack Plan Size</InfoLabel>
                <BlueShadeInfoValue>{size?.value}</BlueShadeInfoValue>
              </WizardRow>
              <WizardRow>
                <InfoLabel>Market</InfoLabel>
                <BlueShadeInfoValue>
                  {availBlock?.market || adHocBlock?.evaluation?.market}
                </BlueShadeInfoValue>
              </WizardRow>
            </>
          )}
          {step > 1 && (
            <>
              {availBlock && <WizardDivider />}
              <WizardRow>
                <InfoLabel>Pick Date</InfoLabel>
                <BlueShadeInfoValue>
                  {getValues('pickDay')
                    ? getValues('pickDay')?.toLocaleDateString()
                    : ''}
                </BlueShadeInfoValue>
              </WizardRow>
              <WizardRow>
                <InfoLabel>Bins Sched.</InfoLabel>
                <BlueShadeInfoValue>
                  {getValues('binsToPick')}
                </BlueShadeInfoValue>
              </WizardRow>
            </>
          )}
          {step > 2 && (
            <>
              {adHocBlock && (
                <>
                  <WizardRow>
                    <InfoLabel>Facility</InfoLabel>
                    <BlueShadeInfoValue>
                      {getValues('packHouse')?.label}
                    </BlueShadeInfoValue>
                  </WizardRow>
                  <WizardRow>
                    <InfoLabel>Market</InfoLabel>
                    <BlueShadeInfoValue>
                      {getValues('market')?.label || '-'}
                    </BlueShadeInfoValue>
                  </WizardRow>
                  <WizardRow>
                    <InfoLabel>Size</InfoLabel>
                    <BlueShadeInfoValue>
                      {getValues('size')?.label || '-'}
                    </BlueShadeInfoValue>
                  </WizardRow>
                </>
              )}
              <WizardRow>
                <InfoLabel>Pool</InfoLabel>
                <BlueShadeInfoValue>
                  {getValues('pool')?.label}
                </BlueShadeInfoValue>
              </WizardRow>
              <WizardRow>
                <InfoLabel>Clean Pick</InfoLabel>
                <BlueShadeInfoValue>
                  {getValues('cleanPick')?.label}
                </BlueShadeInfoValue>
              </WizardRow>
              <WizardRow>
                <InfoLabel>Pick Type</InfoLabel>
                <BlueShadeInfoValue>
                  {getValues('pickType')?.label}
                </BlueShadeInfoValue>
              </WizardRow>
              {getValues('notes') && (
                <>
                  <InfoLabel>Notes:</InfoLabel>
                  <LightGreyNotes>{getValues('notes')}</LightGreyNotes>
                </>
              )}
            </>
          )}
        </SummarySection>
      )}
    </>
  );
};
